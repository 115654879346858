import React, {useEffect, useState} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { useLocation,Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { dhl_edit_order } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';


function Edit_order() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    
    const [dhlEdit, setDhlEdit] = useState([]);
    const [orderCodeDhl, setOrderCodeDhl] = useState();
    const [shipmentDate, setShipmentDate] = useState();
    const [shipmentInfoServiceTypeCode, setShipmentInfoServiceTypeCode] = useState();
    const [shipmentInfoServiceTypeName, setShipmentInfoServiceTypeName] = useState();
    const [shipmentInfoCurrencyCode, setShipmentInfoCurrencyCode] = useState();
    const [shipmentInfoCurrencyName, setShipmentInfoCurrencyName] = useState();
    const [shipmentInfoPaymentInfoCode, setShipmentInfoPaymentInfoCode] = useState();
    const [shipmentInfoPaymentInfoName, setShipmentInfoPaymentInfoName] = useState();
    const [commoditiesNumberOfPieces, setCommoditiesNumberOfPieces] = useState();
    const [commoditiesDescription, setCommoditiesDescription] = useState();
    const [commoditiesComCode, setCommoditiesComCode] = useState();
    const [commoditiesComName, setCommoditiesComName] = useState();
    const [commoditiesQuantity, setCommoditiesQuantity] = useState();
    const [commoditiesUnitPrice, setCommoditiesUnitPrice] = useState();
    const [commoditiesCustomValue, setCommoditiesCustomValue] = useState();
    const [recipientName, setRecipientName] = useState();
    const [recipientCompany, setRecipientCompany] = useState();
    const [recipientPhone, setRecipientPhone] = useState();
    const [recipientEmail, setRecipientEmail] = useState();
    const [recipientAddress, setRecipientAddress] = useState();
    const [recipientPostalCode, setRecipientPostalCode] = useState();
    const [recipientCountryCode, setRecipientCountryCode] = useState();
    const [recipientCountryName, setRecipientCountryName] = useState();
    const [recipientCity, setRecipientCity] = useState();
    const [shipperName, setShipperName] = useState();
    const [shipperCompany, setShipperCompany] = useState();
    const [shipperPhone, setShipperPhone] = useState();
    const [shipperEmail, setShipperEmail] = useState();
    const [shipperAddress, setShipperAddress] = useState();
    const [shipperPostalCode, setShipperPostalCode] = useState();
    const [shipperCountryCode, setShipperCountryCode] = useState();
    const [shipperCountryName, setShipperCountryName] = useState();
    const [shipperCity, setShipperCity] = useState();
    const [exportDeclarationInvoiceNumber, setExportDeclarationInvoiceNumber] = useState();
    const [exportDeclarationDestinationPort, setExportDeclarationDestinationPort] = useState();

    const [idCurrency, setIdCurrency] = useState([]);
    const [selectCurrency, setSelectCurrency] = useState([]);
    const [idCountry, setIdCountry] = useState([]);
    const [idCountryRecipient, setIdCountryRecipient] = useState([]);
    const [idCountryShipper, setIdCountryShipper] = useState([]);
    const [selectCountry, setSelectCountry] = useState([]);
    const [idCom, setIdCom] = useState([]);
    const [idServiceType, setIdServiceType] = useState([]);
    const [selectServiceType, setSelectServiceType] = useState([]);
    const [idPaymentInfo, setIdPaymentInfo] = useState([]);
    const [selectPaymentInfo, setSelectPaymentInfo] = useState([]);

    const [rowsData, setRowsData] = useState([]);

    //Currency Code
    useEffect(() => {
        fetch("https://athaexpress.net/backend/ongkir/api/showCurrencyDhl")
        .then((res) => res.json())
        .then((data) => {
            const dataCurrency = data.data.map((item) => ({
            label: item.currency_name, // <-- input values you are matching
            value: item.currency_code
            }));
            setSelectCurrency(dataCurrency);
        });
    },[]);

    //Country Code
    useEffect(() => {
        fetch("https://athaexpress.net/backend/ongkir/api/showCountryDhl")
        .then((res) => res.json())
        .then((data) => {
            const dataCountry = data.data.map((item) => ({
            label: item.country_name, // <-- input values you are matching
            value: item.country_code
            }));
            setSelectCountry(dataCountry);
            setSelectCountry(dataCountry);
        });
    },[]);

    //ServiceType Code
    useEffect(() => {
        fetch("https://athaexpress.net/backend/ongkir/api/showServiceTypeDhl")
        .then((res) => res.json())
        .then((data) => {
            const dataServiceType = data.data.map((item) => ({
            label: item.service_name, // <-- input values you are matching
            value: item.service_code
            }));
            setSelectServiceType(dataServiceType);
        });
    },[]);

    useEffect(() => {
    let paymentInfo = [{
        label: 'Ex Works (EXW)',
        value: 'EXW'
        },{
        label: 'Free Carrier (FCA)',
        value: 'FCA'
        },{
        label: 'Carriage Paid To (CPT)',
        value: 'CPT'
        },{
        label: 'CFR Cost and Freight (CFR)',
        value: 'CFR'
        },{
        label: 'Carriage and Insurance Paid To (CIP)',
        value: 'CIP'
        },{
        label: 'CIF Cost, Insurance and Freight (CIF)',
        value: 'CIF'
        },{
        label: 'Delivered At Terminal (DAT)',
        value: 'DAT'
        },{
        label: 'Delivered At Place (DAP)',
        value: 'DAP'
        },{
        label: 'Delivered Duty Paid (DDP)',
        value: 'DDP'
        },{
        label: 'FAS Free Alongside Ship (FAS)',
        value: 'FAS'
        },{
        label: 'FOB Free On Board (FOB)',
        value: 'FOB'
        }];

        setSelectPaymentInfo(paymentInfo)
    },[]);
 
    const addTableRows = ()=>{
      
      const rowsInput={
            description:'',
            qom:'',
            quantity:'',
            unit_price:'',
            weight:'',
            mcc:'',
            length:'',
            width:'',
            height:''
      } 
      setRowsData([...rowsData, rowsInput])
      
    }

    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }
 
    const handleChange = (index, evnt)=>{
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }

    function simpan(){
      //setLoading(true);
      setShipperName('ATHA EXPRESS');
      setShipperEmail('atha@gmail.com')
      setShipperPhone('083833555600');
      setShipperCity('JAKARTA');
      setShipperPostalCode('12740');
      setShipperCountryCode('ID');
      setShipperCountryName('INDONESIA');
      setShipperAddress('KALIBATA TIMUR I NO 19');

      let serviceTypeValue,serviceTypeLabel,currencyValue,currencyLabel,paymentInfoValue,paymentInfoLabel,comValue,comLabel,recipientCountryValue,recipientCountryLabel,shipperCountryValue,shipperCountryLabel;

      if(idServiceType.length == undefined){
        serviceTypeValue = idServiceType.idServiceType.value;
        serviceTypeLabel = idServiceType.idServiceType.label;
      }else{
        serviceTypeValue = shipmentInfoServiceTypeCode;
        serviceTypeLabel = shipmentInfoServiceTypeName;
      }
      if(idCurrency.length == undefined){
        currencyValue = idCurrency.idCurrency.value;
        currencyLabel = idCurrency.idCurrency.label;
      }else{
        currencyValue = shipmentInfoCurrencyCode;
        currencyLabel = shipmentInfoCurrencyName;
      }
      if(idPaymentInfo.length == undefined){
        paymentInfoValue = idPaymentInfo.idPaymentInfo.value;
        paymentInfoLabel = idPaymentInfo.idPaymentInfo.label;
      }else{
        paymentInfoValue = shipmentInfoPaymentInfoCode;
        paymentInfoLabel = shipmentInfoPaymentInfoName;
      }

      if(idCom.length == undefined){
        comValue = idCom.idCountry.value;
        comLabel = idCom.idCountry.label;
      }else{
        comValue = commoditiesComCode;
        comLabel = commoditiesComName;
      }

      if(idCountryRecipient.length == undefined){
        recipientCountryValue = idCountryRecipient.idCountryRecipient.value;
        recipientCountryLabel = idCountryRecipient.idCountryRecipient.label;
      }else{
        recipientCountryValue = recipientCountryCode;
        recipientCountryLabel = recipientCountryName;
      }

      if(idCountryShipper.length == undefined){
        shipperCountryValue = idCountryShipper.idCountryShipper.value;
        shipperCountryLabel = idCountryShipper.idCountryShipper.label;
      }else{
        shipperCountryValue = shipperCountryCode;
        shipperCountryLabel = shipperCountryName;
      }

      setProgress(progress + 40)
      setLoading(true)

      console.log(params.get("orderCodeDhl"),shipmentDate,serviceTypeValue,serviceTypeLabel,currencyValue,currencyLabel,paymentInfoValue,paymentInfoLabel,commoditiesNumberOfPieces,commoditiesDescription,comValue,comLabel,commoditiesQuantity,commoditiesUnitPrice,commoditiesCustomValue,recipientName,recipientCompany,recipientPhone,recipientEmail,recipientAddress,recipientPostalCode,recipientCountryValue,recipientCountryLabel,recipientCity,shipperName,shipperCompany,shipperPhone,shipperEmail,shipperAddress,shipperPostalCode,shipperCountryValue,shipperCountryLabel,shipperCity,exportDeclarationDestinationPort,exportDeclarationInvoiceNumber)

      const promise1 = Promise.resolve(dhl_edit_order(params.get("orderCodeDhl"),shipmentDate,serviceTypeValue,serviceTypeLabel,currencyValue,currencyLabel,paymentInfoValue,paymentInfoLabel,commoditiesNumberOfPieces,commoditiesDescription,comValue,comLabel,commoditiesQuantity,commoditiesUnitPrice,commoditiesCustomValue,recipientName,recipientCompany,recipientPhone,recipientEmail,recipientAddress,recipientPostalCode,recipientCountryValue,recipientCountryLabel,recipientCity,shipperName,shipperCompany,shipperPhone,shipperEmail,shipperAddress,shipperPostalCode,shipperCountryValue,shipperCountryLabel,shipperCity,exportDeclarationDestinationPort,exportDeclarationInvoiceNumber,rowsData));
      promise1.then((value) => {
        console.log(value);
        if(value.hasil == '1'){
            //sukses
            setProgress(100);
            setLoading(false);
            alert(value.message);
            history.push('/dhl_order');
            window.location.reload(true);
        }else if(value.hasil == '2'){
            //username password salah
            setProgress(100);
            setLoading(false);
            alert(value.message);
            history.push('/dhl_order');
            window.location.reload(true);
        }else if(value.hasil == '3'){
            //username password salah
            setProgress(100);
            setLoading(false);
            alert(value.message);
            history.push('/dhl_order');
            window.location.reload(true);
        }
      });
    }
  
    useEffect(() => {
    
      const fetchData = async () => {
          
        const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/editOrderLocalDhl/'+params.get("orderCodeDhl"),
        );
        const json = await res.json();
        setDhlEdit(json.data);
        setOrderCodeDhl(json.data[0].orderCodeDhl);
        setShipmentDate(json.data[0].shipmentDate);
        setShipmentInfoServiceTypeCode(json.data[0].shipmentInfoServiceTypeCode);
        setShipmentInfoServiceTypeName(json.data[0].shipmentInfoServiceTypeName);
        setShipmentInfoCurrencyCode(json.data[0].shipmentInfoCurrencyCode);
        setShipmentInfoCurrencyName(json.data[0].shipmentInfoCurrencyName);
        setShipmentInfoPaymentInfoCode(json.data[0].shipmentInfoPaymentInfoCode);
        setShipmentInfoPaymentInfoName(json.data[0].shipmentInfoPaymentInfoName);
        setCommoditiesComCode(json.data[0].commoditiesComCode);
        setCommoditiesComName(json.data[0].commoditiesComName);
        setRecipientCountryCode(json.data[0].recipientCountryCode);
        setRecipientCountryName(json.data[0].recipientCountryName);
        setShipperCountryCode(json.data[0].shipperCountryCode);
        setShipperCountryName(json.data[0].shipperCountryName);
        
        if(json.data[0].commoditiesNumberOfPieces == 'undefined' || json.data[0].commoditiesNumberOfPieces == '0'){
          setCommoditiesNumberOfPieces('');
        }else{
            setCommoditiesNumberOfPieces(json.data[0].commoditiesNumberOfPieces);
        }
        if(json.data[0].commoditiesDescription == 'undefined' || json.data[0].commoditiesDescription == '0'){
          setCommoditiesDescription('');
        }else{
            setCommoditiesDescription(json.data[0].commoditiesDescription);
        }
        if(json.data[0].commoditiesQuantity == 'undefined' || json.data[0].commoditiesQuantity == '0'){
          setCommoditiesQuantity('');
        }else{
          setCommoditiesQuantity(json.data[0].commoditiesQuantity);
        }
        if(json.data[0].commoditiesUnitPrice == 'undefined' || json.data[0].commoditiesUnitPrice == '0'){
          setCommoditiesUnitPrice('');
        }else{
            setCommoditiesUnitPrice(json.data[0].commoditiesUnitPrice);
        }
        if(json.data[0].commoditiesCustomValue == 'undefined' || json.data[0].commoditiesCustomValue == '0'){
          setCommoditiesCustomValue('');
        }else{
            setCommoditiesCustomValue(json.data[0].commoditiesCustomValue);
        }
        if(json.data[0].recipientName == 'undefined' || json.data[0].recipientName == '0'){
          setRecipientName('');
        }else{
            setRecipientName(json.data[0].recipientName);
        }
        if(json.data[0].recipientCompany == 'undefined' || json.data[0].recipientCompany == '0'){
          setRecipientCompany('');
        }else{
            setRecipientCompany(json.data[0].recipientCompany);
        }
        if(json.data[0].recipientPhone == 'undefined' || json.data[0].recipientPhone == '0'){
          setRecipientPhone('');
        }else{
            setRecipientPhone(json.data[0].recipientPhone);
        }
        if(json.data[0].recipientEmail == 'undefined' || json.data[0].recipientEmail == '0'){
          setRecipientEmail('');
        }else{
            setRecipientEmail(json.data[0].recipientEmail);
        }
        if(json.data[0].recipientAddress == 'undefined' || json.data[0].recipientAddress == '0'){
          setRecipientAddress('');
        }else{
            setRecipientAddress(json.data[0].recipientAddress);
        }
        if(json.data[0].recipientPostalCode == 'undefined' || json.data[0].recipientPostalCode == '0'){
          setRecipientPostalCode('');
        }else{
            setRecipientPostalCode(json.data[0].recipientPostalCode);
        }
        if(json.data[0].recipientCity == 'undefined' || json.data[0].recipientCity == '0'){
          setRecipientCity('');
        }else{
            setRecipientCity(json.data[0].recipientCity);
        }
        if(json.data[0].shipperName == 'undefined' || json.data[0].shipperName == '0'){
          setShipperName('');
        }else{
            setShipperName(json.data[0].shipperName);
        }
        if(json.data[0].shipperCompany == 'undefined' || json.data[0].shipperCompany == '0'){
          setShipperCompany('');
        }else{
            setShipperCompany(json.data[0].shipperCompany);
        }
        if(json.data[0].shipperPhone == 'undefined' || json.data[0].shipperPhone == '0'){
          setShipperPhone('');
        }else{
            setShipperPhone(json.data[0].shipperPhone);
        }
        if(json.data[0].shipperEmail == 'undefined' || json.data[0].shipperEmail == '0'){
          setShipperEmail('');
        }else{
            setShipperEmail(json.data[0].shipperEmail);
        }
        if(json.data[0].shipperAddress == 'undefined' || json.data[0].shipperAddress == '0'){
          setShipperAddress('');
        }else{
            setShipperAddress(json.data[0].shipperAddress);
        }
        if(json.data[0].shipperPostalCode == 'undefined' || json.data[0].shipperPostalCode == '0'){
          setShipperPostalCode('');
        }else{
            setShipperPostalCode(json.data[0].shipperPostalCode);
        }
        if(json.data[0].shipperCity == 'undefined' || json.data[0].shipperCity == '0'){
          setShipperCity('');
        }else{
            setShipperCity(json.data[0].shipperCity);
        }
        if(json.data[0].exportDeclarationDestinationPort == 'undefined' || json.data[0].exportDeclarationDestinationPort == '0'){
          setExportDeclarationDestinationPort('');
        }else{
            setExportDeclarationDestinationPort(json.data[0].exportDeclarationDestinationPort);
        }
        if(json.data[0].exportDeclarationInvoiceNumber == 'undefined' || json.data[0].exportDeclarationInvoiceNumber == '0'){
          setExportDeclarationInvoiceNumber('');
        }else{
            setExportDeclarationInvoiceNumber(json.data[0].exportDeclarationInvoiceNumber);
        }
      };
      fetchData();
  
    },[]);

    useEffect(() => {
      let createPresent = [];
      const fetchData = async () => {
        setProgress(progress + 40)
        setLoading(true)
        const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/editDetailOrderLocalDhl/'+params.get("orderCodeDhl"),
        );
        const json = await res.json();
        //console.log(json.data.length);
        
        for (let i = 0; i < json.data.length; i++) {
          //console.log(json.data[i].product_type)
          createPresent=[
              ...createPresent,
              {
                description:json.data[i].description,
                qom:json.data[i].qom,
                quantity:json.data[i].quantity,
                unit_price:json.data[i].unit_price,
                weight:json.data[i].weight,
                mcc:json.data[i].mcc,
                length:json.data[i].length,
                width:json.data[i].width,
                height:json.data[i].height
              }
          ]

          setRowsData(createPresent);
          setProgress(100)
          setLoading(false)
        }
      };
      fetchData();
    },[]);

    
    
    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
    };

    const handleChangeCurrency = (idCurrency) => {
        setIdCurrency({ idCurrency });
    }

    const handleChangeCountryRecipient = (idCountryRecipient) => {
        setIdCountryRecipient({ idCountryRecipient });
    }

    const handleChangeCountryShipper = (idCountryShipper) => {
        setIdCountryShipper({ idCountryShipper });
    }

    const handleChangeCom = (idCountry) => {
        setIdCom({ idCountry });
    }

    const handleChangePaymentInfo = (idPaymentInfo) => {
        setIdPaymentInfo({ idPaymentInfo });
    }

    const handleChangeServiceType = (idServiceType) => {
        setIdServiceType({ idServiceType });
    }
  

  return (
        <div>
            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>DHL Order &nbsp; <img src={require('../../../assets_landingpage/img/dhl_icon.png')} alt="Profile" width="70px" height="50px" class="rounded-circle" /></h1>
                <br />
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item active">DHL Edit Order</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                  <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                    
                    {dhlEdit.map(item => (
                        <React.Fragment>
                        <div class="row mb-3 align-items-center">
                            <h4 style={style_pembatas}>Shipment Info</h4>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Order Code</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="account" defaultValue={orderCodeDhl} disabled name="account" onChange={(e) => setOrderCodeDhl(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Shipment Date</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <input type="datetime-local" id="shipmentDate" name="shipmentDate" defaultValue={shipmentDate} onChange={(e) => setShipmentDate(e.target.value)} class="form-control" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Currency</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <Select
                                        defaultValue={{label: item.shipmentInfoCurrencyName, value: item.shipmentInfoCurrencyCode}}
                                        options={selectCurrency}
                                        onChange={handleChangeCurrency}
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Payment Info</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <Select
                                        defaultValue={{label: item.shipmentInfoPaymentInfoName, value: item.shipmentInfoPaymentInfoCode}}
                                        options={selectPaymentInfo}
                                        onChange={handleChangePaymentInfo}
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Service Type</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <Select
                                        defaultValue={{label: item.shipmentInfoServiceTypeName, value: item.shipmentInfoServiceTypeCode}}
                                        options={selectServiceType}
                                        onChange={handleChangeServiceType}
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-center">
                                <h4 style={style_pembatas}>Commodities</h4>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Number Of Pieces</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="number" id="numberOfPieces" defaultValue={commoditiesNumberOfPieces} name="numberOfPieces" onChange={(e) => setCommoditiesNumberOfPieces(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Description</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="text" id="description" defaultValue={commoditiesDescription} name="description" onChange={(e) => setCommoditiesDescription(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Country Of Manufacture</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <div style={{width: '100%'}}>
                                        <Select
                                            defaultValue={{label: item.commoditiesComName, value: item.commoditiesComCode}}
                                            options={selectCountry}
                                            onChange={handleChangeCom}
                                        />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Quantity</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <input type="number" id="quantity" defaultValue={commoditiesQuantity} name="quantity" onChange={(e) => setCommoditiesQuantity(e.target.value)} class="form-control" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Unit Price</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <input type="number" id="unitPrice" defaultValue={commoditiesUnitPrice} name="unitPrice" onChange={(e) => setCommoditiesUnitPrice(e.target.value)} class="form-control" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Custom Value</label>
                                    </div>
                                    <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <input type="number" id="custom_value" defaultValue={commoditiesCustomValue} name="custom_value" onChange={(e) => setCommoditiesCustomValue(e.target.value)} class="form-control" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3 align-items-center">
                            <h4 style={style_pembatas}>Recipient</h4>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Name</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="recipient_name" defaultValue={recipientName} name="recipient_name" onChange={(e) => setRecipientName(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Company</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="text" id="recipient_company" defaultValue={recipientCompany} name="recipient_company" onChange={(e) => setRecipientCompany(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Phone</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="recipient_phone" defaultValue={recipientPhone} name="recipient_phone" onChange={(e) => setRecipientPhone(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Email</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="text" id="recipient_email" defaultValue={recipientEmail} name="recipient_email" onChange={(e) => setRecipientEmail(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Address</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="recipient_address1" defaultValue={recipientAddress} name="recipient_address1" onChange={(e) => setRecipientAddress(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Postal Code</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="recipient_postalcode" defaultValue={recipientPostalCode} name="recipient_postalcode" onChange={(e) => setRecipientPostalCode(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <Select
                                        defaultValue={{label: item.recipientCountryName, value: item.recipientCountryCode}}
                                        options={selectCountry}
                                        onChange={handleChangeCountryRecipient}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>City</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="recipient_city" name="recipient_city" defaultValue={recipientCity} onChange={(e) => setRecipientCity(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mb-3 align-items-center">
                            <h4 style={style_pembatas}>Shipper</h4>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Name</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="shipper_name" name="shipper_name" defaultValue={shipperName} onChange={(e) => setShipperName(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Company</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="text" id="shipper_company" name="shipper_company" defaultValue={shipperCompany} onChange={(e) => setShipperCompany(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Phone</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="shipper_phone" name="shipper_phone" defaultValue={shipperPhone} onChange={(e) => setShipperPhone(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Email</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="text" id="shipper_email" name="shipper_email" defaultValue={shipperEmail} onChange={(e) => setShipperEmail(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Address</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="shipper_address1" name="shipper_address1" defaultValue={shipperAddress} onChange={(e) => setShipperAddress(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Postal Code</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="shipper_postalcode" name="shipper_postalcode" defaultValue={shipperPostalCode} onChange={(e) => setShipperPostalCode(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Country</label>
                                </div>
                                <div class="col-md-8">
                                    <div style={{width: '100%'}}>
                                        <Select
                                        defaultValue={{label: item.shipperCountryName, value: item.shipperCountryCode}}
                                        options={selectCountry}
                                        onChange={handleChangeCountryShipper}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>City</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="shipper_city" name="shipper_city" defaultValue={shipperCity} onChange={(e) => setShipperCity(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3 align-items-center">
                            <h4 style={style_pembatas}>Export Declaration</h4>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>Destination Port</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="export_declaration_destination_port" name="export_declaration_destination_port" defaultValue={exportDeclarationDestinationPort} onChange={(e) => setExportDeclarationDestinationPort(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                    <label>Invoice Number</label>
                                    </div>
                                    <div class="col-md-8">
                                    <input type="text" id="export_declaration_invoice_number" name="export_declaration_invoice_number" defaultValue={exportDeclarationInvoiceNumber} onChange={(e) => setExportDeclarationInvoiceNumber(e.target.value)} class="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </React.Fragment>
                    ))}
                    
                    
                    <div class="row mb-3 justify-content-center align-items-center">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Export Line Items &nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                            </tr>
                            </thead>
                        <tbody>
                        <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                        </tbody> 
                        </table>
                    </div>
                    <div class="row mb-3 justify-content-center align-items-center">
                        <div class="col-sm-12">
                        <button className="button_simpan" disabled={loading} onClick={simpan}>
                            {loading ? (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            />
                            ):
                            (<i
                            className="fa fa-save"
                            style={{ marginRight: "5px" }}
                            />)} &nbsp;
                            {loading && <span>Harap Tunggu ...</span>}
                            {!loading && <span>Simpan</span>}
                        </button>
                        </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
                  </section>

            </main>
            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Add Type Price</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="row"><div class="col-12"><hr /></div></div>
              </Modal.Body>
            </Modal>

            <Footer_admin></Footer_admin>

            
        </div>
  );
}

export default Edit_order;