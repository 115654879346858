import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_country } from '../../../Context/actions';
import { luwjistik_real_order } from '../../../Context/actions';
import { luwjistik_delete_order } from '../../../Context/actions';
import { luwjistik_saklar_api } from '../../../Context/actions';
import { resi_fwx } from '../../../Context/actions';


import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingSpinner from "../../loading/LoadingSpinner";
import LoadingBar from 'react-top-loading-bar';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

function Order() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  const [trackingNumber, setTrackingNumber] = useState();
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [ffOriginPortCode, setFfOriginPortCode] = useState();
  const [ffDestinationPortCode, setFfDestinationPortCode] = useState();
  const [ccPortCode, setCcPortCode] = useState();
  const [ccIncoterm, setCcIncoterm] = useState();
  const [senderName, setSenderName] = useState();
  const [senderEmail, setSenderEmail] = useState();
  const [senderContactNumber, setSenderContactNumber] = useState();
  const [senderState, setSenderState] = useState();
  const [senderCity, setSenderCity] = useState();
  const [senderProvince, setSenderProvince] = useState();
  const [senderPostalCode, setSenderPostalCode] = useState();
  const [senderCountryCode, setSenderCountryCode] = useState();
  const [senderAddress, setSenderAddress] = useState();
  const [consigneeName, setConsigneeName] = useState();
  const [consigneeContactNumber, setConsigneeContactNumber] = useState();
  const [consigneeAddress, setConsigneeAddress] = useState();
  const [consigneePostalCode, setConsigneePostalCode] = useState();
  const [consigneeCountryCode, setConsigneeCountryCode] = useState();
  const [consigneeCity, setConsigneeCity] = useState();
  const [consigneeProvince, setConsigneeProvince] = useState();
  const [consigneeState, setConsigneeState] = useState();
  const [consigneeEmail, setConsigneeEmail] = useState();
  const [createdDate, setCreatedDate] = useState();

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  const [order, setOrder] = useState([]);
  const [statusApiOrder, setStatusApiOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingResi, setLoadingResi] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const statusApiFetch = async () => {
      const res = await fetch(
        'https://athaexpress.net/backend/ongkir/api/checkApiOrder',
      );
      const json = await res.json();
     
      if(json.status == "true"){
        setStatusApiOrder(json.data);
      }else{
        setStatusApiOrder("-");
      }
      
    };
    statusApiFetch();
    
  },[]);

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://athaexpress.net/backend/ongkir/api/showOrderLocal/'+localStorage.getItem('username'),
  //     );
  //     const json = await res.json();
     
  //     if(json.status == "true"){
  //       setLoading(false);
  //       setOrder(json.data);
  //     }else{
  //       setLoading(false);
  //       setOrder("Data Tidak Tersedia");
  //     }
      
  //   };
  //   fetchData();
    
  // },[]);

  useEffect(() => {
      let url;
      const fetchData = async () => {
      if(query == ''){
          url = 'https://athaexpress.net/backend/ongkir/api/showLuwjistikOrderGetDataPagination/'+localStorage.getItem('username');
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          
          if(json.status == "true"){
              setPage(page);
              setPages(json.pages);
              setRows(json.rows);
          }
          
          };

          
      }

      fetchData();
  }, [page, keyword]);

  useEffect(() => {
      
      let url;
      setProgress(progress + 40);
      if(query == ''){
          
          if(awal == undefined){
              url = 'https://athaexpress.net/backend/ongkir/api/showLuwjistikOrderPagination/0/'+localStorage.getItem('username');
          }else{
              url = 'https://athaexpress.net/backend/ongkir/api/showLuwjistikOrderPagination/'+awal+'/'+localStorage.getItem('username');
          }

          console.log(url);
          const fetchData = async () => {
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          if(json.status == "true"){
            setProgress(100);
            setOrder(json.data);
          }else{
            setProgress(100);
          }
          
          };
          fetchData();
      }
    },[]);

  const changePage = ({ selected }) => {
      setProgress(progress + 40);
      if(query == ''){
          setPage(selected+1);
          
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);

          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showLuwjistikOrderPagination/'+awal+'/'+localStorage.getItem('username'),
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setOrder([]);
              setOrder(json.data);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }else{
          setPage(selected+1);
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          
          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showLuwjistikOrderPaginationSearch/'+awal+'/'+query+'/'+localStorage.getItem('username'),
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setOrder([]);
              setOrder(json.data);
              
              setPages(json.pages);
              setRows(json.rows);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }
      
  };

  function searchData(){
      setProgress(progress + 40);
      setLoadingCari(true);
      const fetchData = async () => { 
      const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/showLuwjistikOrderPaginationSearch/0/'+query+'/'+localStorage.getItem('username'),
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setLoadingCari(false);
          setOrder([]);
          setPages(0);
          setRows(0);
          setPage(1);
          setOrder(json.data);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
          setProgress(100);
          setLoadingCari(false);
          setOrder(json.data);
          setPages(json.pages);
          setRows(json.rows);
          setMsg(
              "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Nama Penerima !"
          );
      }
      
      };
      fetchData(); 
  }; 

  function resetPencarian(){
    setLoadingResetPencarian(true)
    setQuery("");
    setTimeout(
        function() {
          setLoadingResetPencarian(false)
          window.location.reload(true);
        }
        ,
        2000
    );
  }

  // useEffect(() => {
      
  //     setTimeout(()=>{
  //       $("#example2").DataTable({
  //         destroy: true,
  //         "info": false,
  //         fixedColumns: true,
  //         //dom: "rBftlip",
  //         // buttons: [
  //         //   {
    
  //         //   },
  //         // ],
  //         lengthMenu: [
  //           [10, 20, 50, 100, -1],
  //           [10, 20, 50, 100, "All"],
  //         ],
  //         pageLength: 10,
  //     });
    
      
  //     },1000)

      
  // }, [])

  function onoff(){
    if(localStorage.getItem('username') == 'superuser'){
      const promise_login = Promise.resolve(luwjistik_saklar_api());
      setProgress(progress + 40);
      setLoadingApi(true)
      promise_login.then((value) => {
          if(value.hasil == '1'){
              setProgress(100);
              setLoadingApi(false)
              alert(value.message);
              history.push('/luwjistik_order');
              window.location.reload(true);
          }else if(value.hasil == '2'){
              setProgress(100);
              setLoadingApi(false)
              alert(value.message);
              history.push('/luwjistik_order');
              window.location.reload(true);
          }
      });
      
    }else{
      alert('Mohon maaf user anda tidak berwenang untuk aktif API');
    }
  }

  function resi_luwjistik(id){
    if(id == '0'){
      alert('Resi Tidak Tersedia');
    }else{
      setLoadingResi(true);
      setProgress(progress + 40);
      setTimeout(
          function() {
            setLoadingResi(false);
            setProgress(100);
            window.open(id);
          }
          ,
          2000
      );
    }
  }

  function resi_fastworld(id,status){
    setProgress(progress + 40);
    setLoadingResi(true);
    const promise_login = Promise.resolve(resi_fwx(id));

    promise_login.then((value) => {
        if(value.hasil == '1'){
            setProgress(100);
            setLoadingResi(false);
            window.open(value.message);
        }
    });
  }

  
  function edit_order(id){
    history.push('/luwjistik_edit_order?trackingnumber='+id);
  }

  
  function delete_order_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40);
      setLoading(true)
      const promise_login = Promise.resolve(luwjistik_delete_order(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setProgress(100);
                setLoading(false)
                alert(value.message);
                history.push('/luwjistik_order');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                //username password salah
                setProgress(100);
                setLoading(false)
                alert(value.message);
                history.push('/luwjistik_order');
                window.location.reload(true);
            }
        });
        
    } else {
      setProgress(100);
      setLoading(false)
      // Do nothing!
    }
  }

  function create_order(trackingnumber){
    if (window.confirm('Harap Periksa Data Terlebih Dahulu Sebelum Create Order !!!!!')) {
      setProgress(progress + 40);
      setLoadingCreateOrder(true)
      const promise_login = Promise.resolve(luwjistik_real_order(trackingnumber));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setProgress(100);
                setLoadingCreateOrder(false)
                alert("Order Luwjistik Berhasil");
                history.push('/luwjistik_order');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setProgress(100);
                setLoadingCreateOrder(false)
                alert('Pesan Dari Luwjistik : '+ value.reason);
                history.push('/luwjistik_order');
                window.location.reload(true);
            }else if(value.hasil == '3'){
                setProgress(100);
                setLoadingCreateOrder(false)
                alert('Pesan Dari Luwjistik : '+ value.note);
                history.push('/luwjistik_order');
                window.location.reload(true);
            }else{
              setProgress(100);
            }
        });
    } else {
      setProgress(100);
      // Do nothing!
    }
  }

  return (
    
    <div>
      <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    <Header_admin></Header_admin>
  <main id="main" class="main">

    <div class="pagetitle">
      <h1>Data Order Luwjistik &nbsp; <img src={require('../../../assets_landingpage/img/luwjistik_icon.png')} alt="Profile" width="100px" height="50px" /></h1>
      &nbsp;
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
          <li class="breadcrumb-item active">Order</li>
        </ol>
      </nav>
    </div>

    <section class="section">
      <div class="row">
        <div class="col-lg-2">
            <button className="button_tambah_item" onClick={(e) => {
                e.preventDefault();
                window.location.href='/luwjistik_add_order';
                }}>
              <i className="fa fa-plus"
                style={{ marginRight: "5px" }}
              />
              &nbsp;
              Add Order
            </button>
        </div>
        <div class="col-lg-4">
            Status API Luwjistik : {statusApiOrder == "1" ? ("Nyala"):("Mati")} &nbsp;&nbsp;&nbsp;&nbsp;
            {
              statusApiOrder == "1" ? (
                <button type="button" className="button_api_off" disabled={loadingApi} onClick={() => onoff()}>
                  {statusApiOrder == "1" ? 
                    
                      loadingApi ? 
                      (<div>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                        Matikan
                      </div>)
                      :
                      (<div>
                        <i
                        className="fa fa-power-off"
                        style={{ marginRight: "5px" }}
                      />
                      Matikan
                      </div>)
                    
                    :
                    loadingApi ? 
                      (<div>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                        Nyalakan
                      </div>)
                      :
                      (<div>
                        <i
                        className="fa fa-power-off"
                        style={{ marginRight: "5px" }}
                      />
                      Nyalakan
                      </div>)
                  }
                </button>
                )
                :
                (
                <button type="button" className="button_api_on" disabled={loadingApi} onClick={() => onoff()}>
                  {statusApiOrder == "1" ? 
                    loadingApi ? 
                    (<div>
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                      Matikan
                    </div>)
                    :
                    (<div>
                      <i
                      className="fa fa-power-off"
                      style={{ marginRight: "5px" }}
                    />
                    Matikan
                    </div>)
                    :
                    loadingApi ? 
                      (<div>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                        Nyalakan
                      </div>)
                      :
                      (
                        <div>
                          <i
                          className="fa fa-power-off"
                          style={{ marginRight: "5px" }}
                        />
                        Nyalakan
                        </div>
                      )
                    }
                </button>
                )
            }
            
        </div>
        <div class="col-md-6 d-flex flex-row-reverse">
            <form class="form-inline">
            <input
              type="text"
              className="form-control"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Cari sesuatu disini..."
            />
            &nbsp;
            <button type="button" onClick={searchData} className="button_cari" disabled={loadingCari}>
              {loadingCari ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ):
              (<i
                className="fa fa-search"
                style={{ marginRight: "5px" }}
              />)}
              {loadingCari && <span>Cari</span>}
              {!loadingCari && <span>Cari</span>}
            </button>
            &nbsp;
            <button type="button" onClick={resetPencarian} className="button_reset_pencarian" disabled={loadingResetPencarian}>
              {loadingResetPencarian ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ):
              (<i
                className="fa fa-refresh"
                style={{ marginRight: "5px" }}
              />)}
              {loadingResetPencarian && <span>Reset Pencarian</span>}
              {!loadingResetPencarian && <span>Reset Pencarian</span>}
            </button>
            </form>
        </div>
      </div>
      <br />
      <div class="row"> 
        <div class="col-lg-12">
        <div>
            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>Order Code</th>
                    <th>Detail</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                order != 'Data Tidak Tersedia' ?
                order.map((order) => (
                    <tr>
                    <td>{order.trackingnumber}</td>
                    <td>
                      <button type="button" className='button_detail' disabled={loadingDetail}
                        onClick={() => {
                          setLoadingDetail(true);
                          setProgress(progress + 40);
                          setTimeout(
                              function() {
                                setLoadingDetail(false);
                                setProgress(100);
                                setTrackingNumber(order.trackingnumber);
                                setLength(order.length);
                                setWidth(order.width);
                                setHeight(order.height);
                                setWeight(order.weight);
                                setFfOriginPortCode(order.freightforwarding_originportcode);
                                setFfDestinationPortCode(order.freightforwading_destinationportcode);
                                setCcPortCode(order.customsclearance_portcode);
                                setCcIncoterm(order.customsclearance_incoterm);
                                setSenderName(order.sender_name);
                                setSenderEmail(order.sender_email);
                                setSenderContactNumber(order.sender_contactnumber);
                                setSenderState(order.sender_state);
                                setSenderCity(order.sender_city);
                                setSenderProvince(order.sender_province);
                                setSenderPostalCode(order.sender_postalcode);
                                setSenderCountryCode(order.sender_countrycode);
                                setSenderAddress(order.sender_address);
                                setConsigneeName(order.lastmileorder_consignee_name);
                                setConsigneeContactNumber(order.lastmileorder_consignee_contactnumber);
                                setConsigneeAddress(order.lastmileorder_consignee_address);
                                setConsigneePostalCode(order.lastmileorder_consignee_postalcode);
                                setConsigneeCountryCode(order.lastmileorder_consignee_countrycode);
                                setConsigneeCity(order.lastmileorder_consignee_city);
                                setConsigneeProvince(order.lastmileorder_consignee_province);
                                setConsigneeState(order.lastmileorder_consignee_state);
                                setConsigneeEmail(order.lastmileorder_consignee_email);
                                setCreatedDate(order.created_date);
                                setShow(true);
                              }
                              ,
                              2000
                          );
                        }}>
                          {loadingDetail ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-search"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loadingDetail && <span>Detail</span>}
                          {!loadingDetail && <span>Detail</span>}
                        </button>
                    </td>
                    <td>
                      {
                        order.flag_status == '1' ? (<div><img src={require('../../../assets_landingpage/img/silang.png')} width="30px" height="30px" alt="" />&nbsp;&nbsp;&nbsp;<button type="button" onClick={() => create_order(order.trackingnumber)} className="button_create_order" disabled={loadingCreateOrder}>
                          {loadingCreateOrder ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-send"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loadingCreateOrder && <span>Create Order</span>}
                          {!loadingCreateOrder && <span>Create Order</span>}  
                        </button></div>
                        )
                        :
                        (
                        <div>
                          <img src={require('../../../assets_landingpage/img/ceklist.png')} width="30px" height="30px" alt="" />
                          &nbsp;&nbsp;&nbsp;
                          <button type="button" className="button_resi_provider" onClick={() => resi_luwjistik(order.resi_luwjistik)} disabled={loadingResi}>
                            {loadingResi ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-paperclip"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loadingResi && <span>Resi Luwjistik</span>}
                            {!loadingResi && <span>Resi Luwjistik</span>}
                          </button>
                          &nbsp;
                          {
                            <button type="button" className="button_resi_fastworld" onClick={() => resi_fastworld(order.trackingnumber,0)} disabled={loadingResi}>
                              {loadingResi ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-paperclip"
                                style={{ marginRight: "5px" }}
                              />)}
                              {loadingResi && <span>Resi Fastworld</span>}
                              {!loadingResi && <span>Resi Fastworld</span>}
                            </button>
                          }
                          
                        </div>
                        )
                      }
                      
                    </td>
                    <td>
                      <div class="w3-show-inline-block">
                        <div class="w3-bar">
                          <button type="button" className="button_edit" onClick={() => edit_order(order.trackingnumber)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-edit"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Edit</span>}
                            {!loading && <span>Edit</span>}  
                          </button>&nbsp;
                          <button type="button" className="button_hapus" onClick={() => delete_order_onclick(order.trackingnumber)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-times-circle"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Hapus</span>}
                            {!loading && <span>Hapus</span>}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
                :
                <tr>
                    <td colSpan={5}>Data Tidak Tersedia</td>
                </tr>
                }
                </tbody>
            </table>
            <p>
                Total Rows: {rows} Page: {page} of {pages}
            </p>
            <p className="has-text-centered has-text-danger">{msg}</p>

            <div id="container">
                <ReactPaginate
                    previousLabel={"< Prev"}
                    nextLabel={"Next >"}
                    pageCount={pages}
                    onPageChange={changePage}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        </div>

        </div>
      </div>
    </section>

  </main>
  <Modal size="lg" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Data Order</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form class="row g-3 needs-validation" novalidate>
      <div class="row">
        <div class="col-12">
          <label for="lTrackingNumber" class="form-label">Tracking Number</label>
          <div class="input-group has-validation">
            <input type="text" name="trackingNumber" id="trackingNumber" class="form-control" value={trackingNumber} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Created Date</label>
          <div class="input-group has-validation">
            <input type="date" name="created_date" id="created_date" class="form-control" value={createdDate} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Length</label>
          <div class="input-group has-validation">
            <input type="text" name="length" id="length" class="form-control" value={length} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Width</label>
          <div class="input-group has-validation">
            <input type="text" name="width" id="width" class="form-control" value={width} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Height</label>
          <div class="input-group has-validation">
            <input type="text" name="height" id="height" class="form-control" value={height} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Weight</label>
          <div class="input-group has-validation">
            <input type="text" name="weight" id="weight" class="form-control" value={weight} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Origin Port Code (Freight Forwarding)</label>
          <div class="input-group has-validation">
            <input type="text" name="ff_origin_port_code" id="ff_origin_port_code" class="form-control" value={ffOriginPortCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Destination Port Code (Freight Forwarding)</label>
          <div class="input-group has-validation">
            <input type="text" name="ff_destination_port_code" id="ff_destination_port_code" class="form-control" value={ffDestinationPortCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Port Code (Customs Clearance)</label>
          <div class="input-group has-validation">
            <input type="text" name="cc_port_code" id="cc_port_code" class="form-control" value={ccPortCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Incoterm (Customs Clearance)</label>
          <div class="input-group has-validation">
            <input type="text" name="cc_incoterm" id="cc_incoterm" class="form-control" value={ccIncoterm} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Name</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_name" id="sender_name" class="form-control" value={senderName} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Email</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_email" id="sender_email" class="form-control" value={senderEmail} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Contact Number</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_contact_number" id="sender_contact_number" class="form-control" value={senderContactNumber} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender State</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_state" id="sender_state" class="form-control" value={senderState} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender City</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_city" id="sender_city" class="form-control" value={senderCity} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Province</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_province" id="sender_province" class="form-control" value={senderProvince} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Postal Code</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_postalcode" id="sender_postalcode" class="form-control" value={senderPostalCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Country Code</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_country_code" id="sender_country_code" class="form-control" value={senderCountryCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Sender Address</label>
          <div class="input-group has-validation">
            <input type="text" name="sender_address" id="sender_address" class="form-control" value={senderAddress} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Name</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_name" id="consignee_name" class="form-control" value={consigneeName} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Contact Number</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_contact_number" id="consignee_contact_number" class="form-control" value={consigneeContactNumber} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Address</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_address" id="consignee_address" class="form-control" value={consigneeAddress} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Postal Code</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_postal_code" id="consignee_postal_code" class="form-control" value={consigneePostalCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Country Code</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_country_code" id="consignee_country_code" class="form-control" value={consigneeCountryCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee City</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_city" id="consignee_city" class="form-control" value={consigneeCity} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Province</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_province" id="consignee_province" class="form-control" value={consigneeProvince} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee State</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_state" id="consignee_state" class="form-control" value={consigneeState} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Email</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_email" id="consignee_email" class="form-control" value={consigneeEmail} required />
          </div>
        </div>
      </div>
    </form>
    </Modal.Body>
  </Modal>
  <Footer_admin></Footer_admin>
    </div>
            
          
            
        
  );
}

export default Order;