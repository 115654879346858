import axios from 'axios';


export const isAuthenticated = async () => {
  let hasil,message,hakakses;
  const username = localStorage.getItem('username');
  const token = localStorage.getItem('token');

  if(username == "" || token == ""){
    hasil = '2';
    message = "Auth gagal";
    hakakses = "0";
  }else{
    const formData = new FormData();

    formData.append('username', username);
    formData.append('token', token);

    await axios.post('https://athaexpress.net/backend/ongkir/api/auth', formData)
    .then((response) => {

      if(response.data.hasil == 'Sama'){
        const username = localStorage.getItem('username');
        hasil = '1';
        message = username;
        hakakses = response.data.hak_akses;
      }else{
        hasil = '2';
        message = "Auth gagal";
        hakakses = response.data.hak_akses;
      }  
    })
    .catch((error) => {
        hasil = '2';
        message = "Auth gagal";
        hakakses = "0";
    })
  }

  return {
    hasil: hasil,
    message: message,
    hakakses: hakakses,
  };
  
};

export const login = async (username, password) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('username', username);
  formData.append('password', password);

	await axios.post('https://athaexpress.net/backend/ongkir/api/login', formData)
  .then((response) => {

      if(response.data.status == true){
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', username);
        localStorage.setItem('rl', response.data.role);
        localStorage.setItem('nama', response.data.message);
        
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '3';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const logout = async (username, token) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('username', username);
  formData.append('token', token);

	await axios.post('https://athaexpress.net/backend/ongkir/api/logout', formData)
  .then((response) => {

    
      
    if(response.data.status == true){
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('nama');
      
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
      
  })
  .catch((error) => {
    hasil = '2';
    message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_country = async (namaCountry) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('name', namaCountry);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addCountry', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_country = async (id,namaCountry) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('nama', namaCountry);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editCountryProcess', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_country = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deleteCountry/'+id)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};


export const add_type_price = async (type_price) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('type_price', type_price);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addTypePrice', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_type_price = async (id,type_price) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('type_price', type_price);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editTypePriceProcess', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_type_price = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deleteTypePrice/'+id)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_price = async (namaPrice,weightPrice,idTypePricePrice,idCountryPrice) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('price_delivery', namaPrice);
  formData.append('weight', weightPrice);
  formData.append('id_type_price', idTypePricePrice);
  formData.append('id_country', idCountryPrice);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addPrice', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_price = async (id,price_delivery,weight,idTipePrice,tipePrice,idNegara,negara) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('price_delivery', price_delivery);
  formData.append('weight', weight);
  formData.append('id_type_price', idTipePrice);
  formData.append('type_price', tipePrice);
  formData.append('id_country', idNegara);
  formData.append('country', negara);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editPriceProcess', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_price = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deletePrice/'+id)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_type_item = async (idPrice,namaTypeItem) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('idPrice', idPrice);
  formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addTypeItem', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_type_item = async (id,idPrice,namaTypeItem) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('idPrice', idPrice);
  formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editTypeItemProcess', formData)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const delete_type_item = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deleteTypeItem/'+id)
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const luwjistik_order = async (orderCode,length,width,height,weight,senderName,senderEmail,senderContactNumber,senderState,senderCity,senderProvince,senderPostalCode,senderCountryCode,senderAddress,FForiginPortCode,FFdestinationPortCode,CCportCode,CCincoterm,consigneeName,consigneeContactNumber,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeProvince,consigneeState,consigneeEmail,rowsData) => {

  let hasil,message;
  const formData = new FormData();

	formData.append('orderCode', orderCode);
  formData.append('length', length);
  formData.append('width', width);
  formData.append('height', height);
  formData.append('weight', weight);
  formData.append('senderName', senderName);
  formData.append('senderEmail', senderEmail);
  formData.append('senderContactNumber', senderContactNumber);
  formData.append('senderState', senderState);
  formData.append('senderCity', senderCity);
  formData.append('senderProvince', senderProvince);
  formData.append('senderPostalCode', senderPostalCode);
  formData.append('senderCountryCode', senderCountryCode);
  formData.append('senderAddress', senderAddress);
  formData.append('FForiginPortCode', FForiginPortCode);
  formData.append('FFdestinationPortCode', FFdestinationPortCode);
  formData.append('CCportCode', CCportCode);
  formData.append('CCincoterm', CCincoterm);
  formData.append('consigneeName', consigneeName);
  formData.append('consigneeContactNumber', consigneeContactNumber);
  formData.append('consigneeAddress', consigneeAddress);
  formData.append('consigneePostalCode', consigneePostalCode);
  formData.append('consigneeCountryCode', consigneeCountryCode);
  formData.append('consigneeCity', consigneeCity);
  formData.append('consigneeProvince', consigneeProvince);
  formData.append('consigneeState', consigneeState);
  formData.append('consigneeEmail', consigneeEmail);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addOrderLocal', formData)
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
    message = response.data.message;
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const luwjistik_edit_order = async (orderCode,length,width,height,weight,senderName,senderEmail,senderContactNumber,senderState,senderCity,senderProvince,senderPostalCode,senderCountryCode,senderAddress,FForiginPortCode,FFdestinationPortCode,CCportCode,CCincoterm,consigneeName,consigneeContactNumber,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeProvince,consigneeState,consigneeEmail,rowsData) => {

  let hasil,message;
  const formData = new FormData();

	formData.append('orderCode', orderCode);
  formData.append('length', length);
  formData.append('width', width);
  formData.append('height', height);
  formData.append('weight', weight);
  formData.append('senderName', senderName);
  formData.append('senderEmail', senderEmail);
  formData.append('senderContactNumber', senderContactNumber);
  formData.append('senderState', senderState);
  formData.append('senderCity', senderCity);
  formData.append('senderProvince', senderProvince);
  formData.append('senderPostalCode', senderPostalCode);
  formData.append('senderCountryCode', senderCountryCode);
  formData.append('senderAddress', senderAddress);
  formData.append('FForiginPortCode', FForiginPortCode);
  formData.append('FFdestinationPortCode', FFdestinationPortCode);
  formData.append('CCportCode', CCportCode);
  formData.append('CCincoterm', CCincoterm);
  formData.append('consigneeName', consigneeName);
  formData.append('consigneeContactNumber', consigneeContactNumber);
  formData.append('consigneeAddress', consigneeAddress);
  formData.append('consigneePostalCode', consigneePostalCode);
  formData.append('consigneeCountryCode', consigneeCountryCode);
  formData.append('consigneeCity', consigneeCity);
  formData.append('consigneeProvince', consigneeProvince);
  formData.append('consigneeState', consigneeState);
  formData.append('consigneeEmail', consigneeEmail);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editOrderLocalProcess', formData)
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
    message = response.data.message;
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const luwjistik_real_order = async (trackingnumber) => {

  let hasil;
  let resiLuwjistik,serviceType,trackingNumber,partnerName;
  let field,reason,note;

	await axios.get('https://athaexpress.net/backend/ongkir/api/orderReal/' + trackingnumber + '/' + localStorage.getItem('username'))
  .then((response) => {


    if(response.data.status == 'true'){
      hasil = '1';
      resiLuwjistik = response.data.message.resiLuwjistik;
      serviceType = response.data.message.serviceType;
      trackingNumber = response.data.message.trackingNumber;
      partnerName = response.data.message.partnerName;
      field = '';
      reason = '';
      note = '';
    }else{
      hasil = '2';
      resiLuwjistik = '';
      serviceType = '';
      trackingNumber = '';
      partnerName = '';
      field = response.data.message.field;
      reason = response.data.message.reason;
      note = response.data.message.note;
    }
  })
  .catch((error) => {

      hasil = '3';
      resiLuwjistik = '';
      serviceType = '';
      trackingNumber = '';
      partnerName = '';
      field = '';
      reason = '';
      note = error.response.data;
  })

  return {
        hasil: hasil,
        resiLuwjistik: resiLuwjistik,
        serviceType: serviceType,
        trackingNumber: trackingNumber,
        field: field,
        reason: reason,
        note: note
        
  };
};

export const luwjistik_delete_order = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deleteOrderLocal/'+ id  + '/' + localStorage.getItem('username'))
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const luwjistik_saklar_api = async () => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/saklarApiOrder')
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const resi_fwx = async (id) => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/resiFwx/'+id)
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};


export const aramex_order = async (foreignHawb,reference,length,width,height,weight,idProductGroup,productGroupFullname,idProductType,productTypeFullname,idPaymentType,paymentTypeFullname,numberOfPieces,shipperName,shipperCompany,shipperEmail,shipperPhone,shipperCity,shipperPostalCode,shipperCountryCode,shipperAddress,consigneeName,consigneeCompany,consigneePhone,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeEmail,rowsData) => {

  let hasil,message;
  const formData = new FormData();


	formData.append('foreignHawb', foreignHawb);
  formData.append('reference', reference);
  formData.append('length', length);
  formData.append('width', width);
  formData.append('height', height);
  formData.append('weight', weight);
  formData.append('idProductGroup', idProductGroup);
  formData.append('productGroupFullname', productGroupFullname);
  formData.append('idProductType', idProductType);
  formData.append('productTypeFullname', productTypeFullname);
  formData.append('idPaymentType', idPaymentType);
  formData.append('paymentTypeFullname', paymentTypeFullname);
  formData.append('numberOfPieces', numberOfPieces);
  formData.append('shipperName', shipperName);
  formData.append('shipperCompany', shipperCompany);
  formData.append('shipperEmail', shipperEmail);
  formData.append('shipperPhone', shipperPhone);
  formData.append('shipperCity', shipperCity);
  formData.append('shipperPostalCode', shipperPostalCode);
  formData.append('shipperCountryCode', shipperCountryCode);
  formData.append('shipperAddress', shipperAddress);
  formData.append('consigneeName', consigneeName);
  formData.append('consigneeCompany', consigneeCompany);
  formData.append('consigneePhone', consigneePhone);
  formData.append('consigneeAddress', consigneeAddress);
  formData.append('consigneePostalCode', consigneePostalCode);
  formData.append('consigneeCountryCode', consigneeCountryCode);
  formData.append('consigneeCity', consigneeCity);
  formData.append('consigneeEmail', consigneeEmail);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addOrderLocalAramex', formData)
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const aramex_edit_order = async (oldForeignHawb,foreignHawb,reference,length,width,height,weight,idProductGroup,productGroupFullname,idProductType,productTypeFullname,idPaymentType,paymentTypeFullname,numberOfPieces,shipperName,shipperCompany,shipperEmail,shipperPhone,shipperCity,shipperPostalCode,shipperCountryCode,shipperAddress,consigneeName,consigneeCompany,consigneePhone,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeEmail,rowsData) => {

  let hasil,message;
  const formData = new FormData();

  formData.append('oldForeignHawb', oldForeignHawb);
	formData.append('foreignHawb', foreignHawb);
  formData.append('reference', reference);
  formData.append('length', length);
  formData.append('width', width);
  formData.append('height', height);
  formData.append('weight', weight);
  formData.append('idProductGroup', idProductGroup);
  formData.append('productGroupFullname', productGroupFullname);
  formData.append('idProductType', idProductType);
  formData.append('productTypeFullname', productTypeFullname);
  formData.append('idPaymentType', idPaymentType);
  formData.append('paymentTypeFullname', paymentTypeFullname);
  formData.append('numberOfPieces', numberOfPieces);
  formData.append('shipperName', shipperName);
  formData.append('shipperCompany', shipperCompany);
  formData.append('shipperEmail', shipperEmail);
  formData.append('shipperPhone', shipperPhone);
  formData.append('shipperCity', shipperCity);
  formData.append('shipperPostalCode', shipperPostalCode);
  formData.append('shipperCountryCode', shipperCountryCode);
  formData.append('shipperAddress', shipperAddress);
  formData.append('consigneeName', consigneeName);
  formData.append('consigneeCompany', consigneeCompany);
  formData.append('consigneePhone', consigneePhone);
  formData.append('consigneeAddress', consigneeAddress);
  formData.append('consigneePostalCode', consigneePostalCode);
  formData.append('consigneeCountryCode', consigneeCountryCode);
  formData.append('consigneeCity', consigneeCity);
  formData.append('consigneeEmail', consigneeEmail);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editOrderLocalProcessAramex', formData)
  .then((response) => {
    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
    message = response.data.message;
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const aramex_delete_order = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deleteOrderLocalAramex/'+ id  + '/' + localStorage.getItem('username'))
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const aramex_saklar_api = async () => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/saklarApiOrderAramex')
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const aramex_real_order = async (foreignhawb) => {

  let hasil;
  let id,resi_aramex,hasilForeignhawb,error_code,error_message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/orderRealAramex/' + foreignhawb + '/' + localStorage.getItem('username'))
  .then((response) => {


    if(response.data.status == 'true'){
      hasil = '1';
      id = response.data.message.id;
      resi_aramex = response.data.message.resi_aramex;
      hasilForeignhawb = response.data.message.foreignhawb;
      error_code = '';
      error_message = '';
    }else{
      hasil = '2';
      id = '';
      resi_aramex = '';
      hasilForeignhawb = '';
      error_code = response.data.message.error_code;
      error_message = response.data.message.error_message;
    }
  })
  .catch((error) => {

      hasil = '3';
      id = '';
      resi_aramex = '';
      hasilForeignhawb = '';
      error_code = '';
      error_message = error.response.data;
  })

  return {
        hasil: hasil,
        id: id,
        resi_aramex: resi_aramex,
        hasilForeignhawb: hasilForeignhawb,
        error_code: error_code,
        error_message: error_message
        
  };
};

export const resi_fwx_aramex = async (id) => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/resiFwxAramex/'+id)
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const dhl_order = async (shipmentDate,shipmentInfoServiceTypeCode,shipmentInfoServiceTypeName,shipmentInfoCurrencyCode,shipmentInfoCurrencyName,shipmentInfoPaymentInfoCode,shipmentInfoPaymentInfoName,commoditiesNumberOfPieces,commoditiesDescription,commoditiesComCode,commoditiesComName,commoditiesQuantity,commoditiesUnitPrice,commoditiesCustomValue,recipientName,recipientCompany,recipientPhone,recipientEmail,recipientAddress,recipientPostalCode,recipientCountryCode,recipientCountryName,recipientCity,shipperName,shipperCompany,shipperPhone,shipperEmail,shipperAddress,shipperPostalCode,shipperCountryCode,shipperCountryName,shipperCity,exportDeclarationDestinationPort,exportDeclarationInvoiceNumber,rowsData) => {

  let hasil,message;
  const formData = new FormData();

  formData.append('shipmentDate', shipmentDate);
  formData.append('shipmentInfoServiceTypeCode', shipmentInfoServiceTypeCode);
  formData.append('shipmentInfoServiceTypeName', shipmentInfoServiceTypeName);
  formData.append('shipmentInfoCurrencyCode', shipmentInfoCurrencyCode);
  formData.append('shipmentInfoCurrencyName', shipmentInfoCurrencyName);
  formData.append('shipmentInfoPaymentInfoCode', shipmentInfoPaymentInfoCode);
  formData.append('shipmentInfoPaymentInfoName', shipmentInfoPaymentInfoName);
  formData.append('commoditiesNumberOfPieces', commoditiesNumberOfPieces);
  formData.append('commoditiesDescription', commoditiesDescription);
  formData.append('commoditiesComCode', commoditiesComCode);
  formData.append('commoditiesComName', commoditiesComName);
  formData.append('commoditiesQuantity', commoditiesQuantity);
  formData.append('commoditiesUnitPrice', commoditiesUnitPrice);
  formData.append('commoditiesCustomValue', commoditiesCustomValue);
  formData.append('recipientName', recipientName);
  formData.append('recipientCompany', recipientCompany);
  formData.append('recipientPhone', recipientPhone);
  formData.append('recipientEmail', recipientEmail);
  formData.append('recipientAddress', recipientAddress);
  formData.append('recipientPostalCode', recipientPostalCode);
  formData.append('recipientCountryCode', recipientCountryCode);
  formData.append('recipientCountryName', recipientCountryName);
  formData.append('recipientCity', recipientCity);
  formData.append('shipperName', shipperName);
  formData.append('shipperCompany', shipperCompany);
  formData.append('shipperPhone', shipperPhone);
  formData.append('shipperEmail', shipperEmail);
  formData.append('shipperAddress', shipperAddress);
  formData.append('shipperPostalCode', shipperPostalCode);
  formData.append('shipperCountryCode', shipperCountryCode);
  formData.append('shipperCountryName', shipperCountryName);
  formData.append('shipperCity', shipperCity);
  formData.append('exportDeclarationDestinationPort', exportDeclarationDestinationPort);
  formData.append('exportDeclarationInvoiceNumber', exportDeclarationInvoiceNumber);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addOrderLocalDhl', formData)
  .then((response) => {

    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {
      hasil = '3';
      message = error.message.errorInfo;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const dhl_edit_order = async (orderCodeDhl,shipmentDate,serviceTypeValue,serviceTypeLabel,currencyValue,currencyLabel,paymentInfoValue,paymentInfoLabel,commoditiesNumberOfPieces,commoditiesDescription,comValue,comLabel,commoditiesQuantity,commoditiesUnitPrice,commoditiesCustomValue,recipientName,recipientCompany,recipientPhone,recipientEmail,recipientAddress,recipientPostalCode,recipientCountryValue,recipientCountryLabel,recipientCity,shipperName,shipperCompany,shipperPhone,shipperEmail,shipperAddress,shipperPostalCode,shipperCountryValue,shipperCountryLabel,shipperCity,exportDeclarationDestinationPort,exportDeclarationInvoiceNumber,rowsData) => {

  let hasil,message;
  const formData = new FormData();

  formData.append('orderCodeDhl', orderCodeDhl);
  formData.append('shipmentDate', shipmentDate);
  formData.append('shipmentInfoServiceTypeCode', serviceTypeValue);
  formData.append('shipmentInfoServiceTypeName', serviceTypeLabel);
  formData.append('shipmentInfoCurrencyCode', currencyValue);
  formData.append('shipmentInfoCurrencyName', currencyLabel);
  formData.append('shipmentInfoPaymentInfoCode', paymentInfoValue);
  formData.append('shipmentInfoPaymentInfoName', paymentInfoLabel);
  formData.append('commoditiesNumberOfPieces', commoditiesNumberOfPieces);
  formData.append('commoditiesDescription', commoditiesDescription);
  formData.append('commoditiesComCode', comValue);
  formData.append('commoditiesComName', comLabel);
  formData.append('commoditiesQuantity', commoditiesQuantity);
  formData.append('commoditiesUnitPrice', commoditiesUnitPrice);
  formData.append('commoditiesCustomValue', commoditiesCustomValue);
  formData.append('recipientName', recipientName);
  formData.append('recipientCompany', recipientCompany);
  formData.append('recipientPhone', recipientPhone);
  formData.append('recipientEmail', recipientEmail);
  formData.append('recipientAddress', recipientAddress);
  formData.append('recipientPostalCode', recipientPostalCode);
  formData.append('recipientCountryCode', recipientCountryValue);
  formData.append('recipientCountryName', recipientCountryLabel);
  formData.append('recipientCity', recipientCity);
  formData.append('shipperName', shipperName);
  formData.append('shipperCompany', shipperCompany);
  formData.append('shipperPhone', shipperPhone);
  formData.append('shipperEmail', shipperEmail);
  formData.append('shipperAddress', shipperAddress);
  formData.append('shipperPostalCode', shipperPostalCode);
  formData.append('shipperCountryCode', shipperCountryValue);
  formData.append('shipperCountryName', shipperCountryLabel);
  formData.append('shipperCity', shipperCity);
  formData.append('exportDeclarationDestinationPort', exportDeclarationDestinationPort);
  formData.append('exportDeclarationInvoiceNumber', exportDeclarationInvoiceNumber);
  formData.append('userCode', localStorage.getItem('username'));
  formData.append('rowsData', JSON.stringify(rowsData));
  // formData.append('idPrice', idPrice);
  // formData.append('namaTypeItem', namaTypeItem);

	await axios.post('https://athaexpress.net/backend/ongkir/api/editOrderLocalProcessDhl', formData)
  .then((response) => {
    if(response.data.status == 'true'){
       
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {
      hasil = '3';
      message = error;
  })
  
  return {
    hasil: hasil,
    message: message
  };
	
};

export const dhl_delete_order = async (id) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/deleteOrderLocalDhl/'+ id  + '/' + localStorage.getItem('username'))
  .then((response) => {

      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const dhl_saklar_api = async () => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/saklarApiOrderDhl')
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const dhl_real_order = async (orderCodeDhl) => {

  let hasil;
  let resi_dhl_commercial,resi_dhl_perpaket,hasilOrderCodeDhl,error_code,error_message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/orderRealDhl/' + orderCodeDhl + '/' + localStorage.getItem('username'))
  .then((response) => {


    if(response.data.status == 'true'){
      hasil = '1';
      resi_dhl_commercial = response.data.message.resi_dhl_commercial;
      resi_dhl_perpaket = response.data.message.resi_dhl_perpaket;
      hasilOrderCodeDhl = response.data.message.orderCodeDhl;
      error_code = '';
      error_message = '';
    }else{
      hasil = '2';
      resi_dhl_commercial = '';
      resi_dhl_perpaket = '';
      hasilOrderCodeDhl = '';
      error_code = response.data.message.error_code;
      error_message = response.data.message.error_message;
    }
  })
  .catch((error) => {

      hasil = '3';
      resi_dhl_commercial = '';
      resi_dhl_perpaket = '';
      hasilOrderCodeDhl = '';
      error_code = '';
      error_message = error.response.data;
  })

  return {
        hasil: hasil,
        resi_dhl_commercial: resi_dhl_commercial,
        resi_dhl_perpaket: resi_dhl_perpaket,
        hasilOrderCodeDhl: hasilOrderCodeDhl,
        error_code: error_code,
        error_message: error_message
        
  };
};

export const resi_fwx_dhl = async (id) => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/resiFwxDhl/'+id)
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const change_password = async (username,oldpassword,new_password) => {
  let hasil,message;
  const formData = new FormData();


	await axios.get('https://athaexpress.net/backend/ongkir/api/changepassword/'+username+'/'+oldpassword+'/'+new_password)
  .then((response) => {

      if(response.data.status == 'true'){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const saklar_user = async (id) => {

  let hasil,message;

	await axios.get('https://athaexpress.net/backend/ongkir/api/saklar_user/'+id)
  .then((response) => {

   

    if(response.data.status == 'true'){
      hasil = '1';
      message = response.data.message;
    }else{
      hasil = '2';
      message = response.data.message;
    }
  })
  .catch((error) => {

      hasil = '2';
      message = error.response.data;
  })

  return {
        hasil: hasil,
        message: message
  };
};

export const edit_profile = async (parUsername,username,name,address,role,akses,logoresi) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('parusername', parUsername);
  formData.append('usercode', username);
  formData.append('name', name);
  formData.append('address', address);
  formData.append('role', role);
  formData.append('hakakses', akses);
  formData.append('logoresi', logoresi);
  formData.append('changeby', localStorage.getItem('username'));

	await axios.post('https://athaexpress.net/backend/ongkir/api/editProfileProcess', formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const edit_user = async (id,username,name,address,role,akses,logoresi) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('id', id);
  formData.append('usercode', username);
  formData.append('name', name);
  formData.append('address', address);
  formData.append('role', role);
  formData.append('hakakses', akses);
  formData.append('logoresi', logoresi);
  formData.append('changeby', localStorage.getItem('username'));

	await axios.post('https://athaexpress.net/backend/ongkir/api/editUserProcess', formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};

export const add_user = async (username,password,name,address,role,akses,logoresi) => {
  let hasil,message;
  const formData = new FormData();

  formData.append('usercode', username);
  formData.append('password', password);
  formData.append('name', name);
  formData.append('address', address);
  formData.append('role', role);
  formData.append('hakakses', akses);
  formData.append('logoresi', logoresi);

	await axios.post('https://athaexpress.net/backend/ongkir/api/addUserProcess', formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then((response) => {
      //console.log(response);
      if(response.data.status == true){
       
        hasil = '1';
        message = response.data.message;
      }else{
        hasil = '2';
        message = response.data.message;
      }
  })
  .catch((error) => {
      //console.log(error.message);
      hasil = '2';
      message = error.response.data;
  })

  return {
    hasil: hasil,
    message: message,
  };
	
};