import React, {useEffect, useState} from 'react';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { aramex_order } from '../../../Context/actions';

import LoadingBar from 'react-top-loading-bar';


function Add_order() {
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [foreignHawb, setForeignHawb] = useState();
    const [reference, setReference] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();
    const [numberOfPieces, setNumberOfPieces] = useState();
    const [shipperName, setShipperName] = useState();
    const [shipperCompany, setShipperCompany] = useState();
    const [shipperPhone, setShipperPhone] = useState();
    const [shipperEmail, setShipperEmail] = useState();
    const [shipperAddress, setShipperAddress] = useState();
    const [shipperCity, setShipperCity] = useState();
    const [shipperPostalCode, setShipperPostalCode] = useState();
    const [shipperCountryCode, setShipperCountryCode] = useState();
    const [consigneeName, setConsigneeName] = useState();
    const [consigneeCompany, setConsigneeCompany] = useState();
    const [consigneePhone, setConsigneePhone] = useState();
    const [consigneeEmail, setConsigneeEmail] = useState();
    const [consigneeAddress, setConsigneeAddress] = useState();
    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneePostalCode, setConsigneePostalCode] = useState();
    const [consigneeCountryCode, setConsigneeCountryCode] = useState();

    const [idProductType, setIdProductType] = useState([]);
    const [productType, setProductType] = useState([]);
    const [idProductGroup, setIdProductGroup] = useState([]);
    const [productGroup, setProductGroup] = useState([]);
    const [idPaymentType, setIdPaymentType] = useState([]);
    const [paymentType, setPaymentType] = useState([]);


    const [rowsData, setRowsData] = useState([]);
 

    useEffect(() => {
      let productType = [{
          label: 'Priority Document Express (PDX)',
          value: 'PDX'
        },{
          label: 'Priority Parcel Express (PPX)',
          value: 'PPX'
        },{
          label: 'Priority Letter Express (PLX)',
          value: 'PLX'
        },{
          label: 'Deferred Document Express (DDX)',
          value: 'DDX'
        },{
          label: 'Deferred Parcel Express (DPX)',
          value: 'DPX'
        },{
          label: 'Ground Document Express (GDX)',
          value: 'GDX'
        },{
          label: 'Ground Parcel Express (GPX)',
          value: 'GPX'
        },{
          label: 'Economy Parcel Express (EPX)',
          value: 'EPX'
        }];

      let productGroup = [{
          label: 'Express (EXP)',
          value: 'EXP'
        },{
          label: 'Domestic (DOM)',
          value: 'DOM'
        }];

      let paymentType = [{
          label: 'Prepaid (P)',
          value: 'P'
        },{
          label: 'Collect (C)',
          value: 'C'
        },{
          label: 'Third Party (3)',
          value: '3'
        }];

        setProductType(productType)
        setProductGroup(productGroup)
        setPaymentType(paymentType)
    },[]);

    const addTableRows = ()=>{
  
        const rowsInput={
          package_type:'',
          quantity:'',
          weight:'',
          comment:''
        } 
        setRowsData([...rowsData, rowsInput])
      
    }
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }
 
    const handleChange = (index, evnt)=>{
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }


    function simpan(){

      setLoading(true);
      setShipperName('ATHA EXPRESS');
      setShipperEmail('atha@gmail.com')
      setShipperPhone('083833555600');
      setShipperCity('JAKARTA');
      setShipperPostalCode('12740');
      setShipperCountryCode('ID');
      setShipperAddress('KALIBATA TIMUR I NO 19');

      setProgress(progress + 40)
      setLoading(true)

      const promise1 = Promise.resolve(aramex_order(foreignHawb,reference,length,width,height,weight,idProductGroup.idProductGroup.value,idProductGroup.idProductGroup.label,idProductType.idProductType.value,idProductType.idProductType.label,idPaymentType.idPaymentType.value,idPaymentType.idPaymentType.label,numberOfPieces,shipperName,shipperCompany,shipperEmail,shipperPhone,shipperCity,shipperPostalCode,shipperCountryCode,shipperAddress,consigneeName,consigneeCompany,consigneePhone,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeEmail,rowsData));
      promise1.then((value) => {
        console.log(value.hasil);
        if(value.hasil == '1'){
            //sukses
            setProgress(100);
            setLoading(false);
            alert(value.message);
            history.push('/aramex_order');
            window.location.reload(true);
        }else if(value.hasil == '2'){
            setProgress(100);
            setLoading(false);
            alert(value.message);
        }
      });
    }
   

    

    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
     };

     const form_order = {
      color: "#061C54",
      fontStyle: "bold"
     };

    const handleChangeProductGroup = (idProductGroup) => {
      setIdProductGroup({ idProductGroup });
    }
  
    const handleChangeProductType = (idProductType) => {
      setIdProductType({ idProductType });
    }
     
    const handleChangePaymentType = (idPaymentType) => {
      setIdPaymentType({ idPaymentType });
    }

  return (
      <div>
          <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <Header_admin></Header_admin>
          <main id="main" class="main">

            <div class="pagetitle">
              <h1>Aramex Order&nbsp; <img src={require('../../../assets_landingpage/img/aramex_icon.png')} alt="Profile" width="80px" height="40px" /></h1>
              <br />
              <nav>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li class="breadcrumb-item active">Aramex Order</li>
                </ol>
              </nav>
            </div>

            <section class="section">
            <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Form Create Order</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Foreign HAWB</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="foreign_hawb" name="foreign_hawb" onChange={(e) => setForeignHawb(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Reference</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="reference" name="reference" onChange={(e) => setReference(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Length</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="length" name="length" onChange={(e) => setLength(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Width</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="width" name="width" onChange={(e) => setWidth(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Height</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="height" name="height" onChange={(e) => setHeight(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Weight</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="weight" name="weight" onChange={(e) => setWeight(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Product Group</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={productGroup}
                              onChange={handleChangeProductGroup}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Product Type</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={productType}
                              onChange={handleChangeProductType}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Payment Type</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={paymentType}
                              onChange={handleChangePaymentType}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Quantity Item</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="number_of_pieces" name="number_of_pieces" onChange={(e) => setNumberOfPieces(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Shipper</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Name</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_name" name="shipper_name" onChange={(e) => setShipperName(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Company</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="shipper_company" name="shipper_company" onChange={(e) => setShipperCompany(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_phone" name="shipper_phone" onChange={(e) => setShipperPhone(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Email</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="shipper_email" name="shipper_email" onChange={(e) => setShipperEmail(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Address</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_address" name="shipper_address" onChange={(e) => setShipperAddress(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>City</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="shipper_city" name="shipper_city" onChange={(e) => setShipperCity(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Postal Code</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_postalcode" name="shipper_postalcode" onChange={(e) => setShipperPostalCode(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Country Code</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="shipper_countrycode" name="shipper_countrycode" onChange={(e) => setShipperCountryCode(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Consignee</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Name</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_name" name="consignee_name" onChange={(e) => setConsigneeName(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Company</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="consignee_company" name="consignee_company" onChange={(e) => setConsigneeCompany(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_phone" name="consignee_phone" onChange={(e) => setConsigneePhone(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Email</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="consignee_email" name="consignee_email" onChange={(e) => setConsigneeEmail(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Address</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_address" name="consignee_address" onChange={(e) => setConsigneeAddress(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>City</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="consignee_city" name="consignee_city" onChange={(e) => setConsigneeCity(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Postal Code</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_postalcode" name="consignee_postalcode" onChange={(e) => setConsigneePostalCode(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Country Code</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="consignee_countrycode" name="consignee_countrycode" onChange={(e) => setConsigneeCountryCode(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Item</h4>
              </div>
              <div class="row mb-3 justify-content-center align-items-center">
                  <table className="table">
                      <thead>
                      <tr>
                          <th>Tambah Item &nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                      </tr>
                      </thead>
                  <tbody>
                  <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                  </tbody> 
                  </table>
              </div>
              <div class="row mb-3 justify-content-center align-items-center">
                <div class="col-sm-12">
                  <button className="button_simpan" disabled={loading} onClick={simpan}>
                    {loading ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ):
                    (<i
                      className="fa fa-save"
                      style={{ marginRight: "5px" }}
                    />)} &nbsp;
                    {loading && <span>Harap Tunggu ...</span>}
                    {!loading && <span>Simpan</span>}
                  </button>
                </div>
              </div>
          </div>
        </div>

      </div>
    </div>
            </section>

          </main>
          <Footer_admin></Footer_admin>

          
      </div>
  );
}

export default Add_order;