import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { aramex_real_order } from '../../../Context/actions';
import { aramex_delete_order } from '../../../Context/actions';
import { aramex_saklar_api } from '../../../Context/actions';
import { resi_fwx_aramex } from '../../../Context/actions';


import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

function Order() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  const [foreignHawb, setForeignHawb] = useState();
  const [reference, setReference] = useState();
  const [shipperName, setShipperName] = useState();
  const [shipperCompany, setShipperCompany] = useState();
  const [shipperPhone, setShipperPhone] = useState();
  const [shipperEmail, setShipperEmail] = useState();
  const [shipperAddress, setShipperAddress] = useState();
  const [shipperCity, setShipperCity] = useState();
  const [shipperPostalCode, setShipperPostalCode] = useState();
  const [shipperCountryCode, setShipperCountryCode] = useState();
  const [consigneeName, setConsigneeName] = useState();
  const [consigneeCompany, setConsigneeCompany] = useState();
  const [consigneePhone, setConsigneePhone] = useState();
  const [consigneeEmail, setConsigneeEmail] = useState();
  const [consigneeAddress, setConsigneeAddress] = useState();
  const [consigneeCity, setConsigneeCity] = useState();
  const [consigneePostalCode, setConsigneePostalCode] = useState();
  const [consigneeCountryCode, setConsigneeCountryCode] = useState();
  const [createdDate, setCreatedDate] = useState();

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");


  const [order, setOrder] = useState([]);
  const [statusApiOrder, setStatusApiOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingResi, setLoadingResi] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    const statusApiFetch = async () => {
      const res = await fetch(
        'https://athaexpress.net/backend/ongkir/api/checkApiOrderAramex',
      );
      const json = await res.json();
     
      if(json.status == "true"){
        setStatusApiOrder(json.data);
      }else{
        setStatusApiOrder("-");
      }
      
    };
    statusApiFetch();
    
  },[]);

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://athaexpress.net/backend/ongkir/api/showOrderLocalAramex/'+localStorage.getItem('username'),
  //     );
  //     const json = await res.json();
     
  //     if(json.status == "true"){
  //       setLoading(false);
  //       setOrder(json.data);
  //     }else{
  //       setLoading(false);
  //       setOrder("Data Tidak Tersedia");
  //     }
      
  //   };
  //   fetchData();
    
  // },[]);

useEffect(() => {
    let url;
    const fetchData = async () => {
    if(query == ''){
        url = 'https://athaexpress.net/backend/ongkir/api/showAramexOrderGetDataPagination/'+localStorage.getItem('username');
        const res = await fetch(
            url,
        );
        const json = await res.json();
        
        
        if(json.status == "true"){
            setPage(page);
            setPages(json.pages);
            setRows(json.rows);
        }
        
        };

        
    }

    fetchData();
}, [page, keyword]);

useEffect(() => {
    let url;
    setProgress(progress + 40);
    setLoading(true);
    if(query == ''){
       
        if(awal == undefined){
            url = 'https://athaexpress.net/backend/ongkir/api/showAramexOrderPagination/0/'+localStorage.getItem('username');
        }else{
            url = 'https://athaexpress.net/backend/ongkir/api/showAramexOrderPagination/'+awal+'/'+localStorage.getItem('username');
        }

        const fetchData = async () => {
        const res = await fetch(
            url,
        );
        const json = await res.json();
        
        if(json.status == "true"){
          setProgress(100);
          setLoading(false);
          setOrder(json.data);
        }else{
          setProgress(100);
          setLoading(false);
        }
        
        };
        fetchData();
    }
  },[]);

const changePage = ({ selected }) => {
    setProgress(progress + 40);
    if(query == ''){
        setPage(selected+1);
        
        let awal;
        
        awal = (Number((selected+1)+'0') - 10)

        setAwal(awal);

        const fetchData = async () => {
        const res = await fetch(
            'https://athaexpress.net/backend/ongkir/api/showAramexOrderPagination/'+awal+'/'+localStorage.getItem('username'),
        );
        const json = await res.json();
        
        if(json.status == "true"){
            setProgress(100);
            setOrder([]);
            setOrder(json.data);
        }else{
            setProgress(100);
            setMsg(
                "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
            );
        }
        
        };
        fetchData();
    }else{
        setPage(selected+1);
        let awal;
        
        awal = (Number((selected+1)+'0') - 10)

        setAwal(awal);
        
        const fetchData = async () => {
        const res = await fetch(
            'https://athaexpress.net/backend/ongkir/api/showAramexOrderPaginationSearch/'+awal+'/'+query+'/'+localStorage.getItem('username'),
        );
        const json = await res.json();
        
        if(json.status == "true"){
            setProgress(100);
            setOrder([]);
            setOrder(json.data);
            
            setPages(json.pages);
            setRows(json.rows);
        }else{
            setProgress(100);
            setMsg(
                "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
            );
        }
        
        };
        fetchData();
    }
    
};

function searchData(){
    setProgress(progress + 40);
    setLoadingCari(true);
    const fetchData = async () => { 
    const res = await fetch(
        'https://athaexpress.net/backend/ongkir/api/showAramexOrderPaginationSearch/0/'+query+'/'+localStorage.getItem('username'),
    );
    const json = await res.json();
    
    if(json.status == "true"){
        setLoadingCari(false);
        setProgress(100);
        setOrder([]);
        setPages(0);
        setRows(0);
        setPage(1);
        setOrder(json.data);
        setPages(json.pages);
        setRows(json.rows);
        
    }else{
        setLoadingCari(false);
        setProgress(100);
        setOrder(json.data);
        setPages(json.pages);
        setRows(json.rows);
        setMsg(
            "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Nama Penerima !"
        );
    }
    
    };
    fetchData(); 
}; 

function resetPencarian(){
  setLoadingResetPencarian(true)
  setQuery("");
  setTimeout(
      function() {
        setLoadingResetPencarian(false)
        window.location.reload(true);
      }
      ,
      2000
  );
}

  // useEffect(() => {
    
  //     setTimeout(()=>{
  //       $("#example2").DataTable({
  //         destroy: true,
  //         "info": false,
  //         fixedColumns: true,
  //         //dom: "rBftlip",
  //         // buttons: [
  //         //   {
    
  //         //   },
  //         // ],
  //         lengthMenu: [
  //           [10, 20, 50, 100, -1],
  //           [10, 20, 50, 100, "All"],
  //         ],
  //         pageLength: 10,
  //     });
    
      
  //     },1000)

      
  // }, [])

  function onoff(){
    if(localStorage.getItem('username') == 'superuser'){
      const promise_login = Promise.resolve(aramex_saklar_api());
      setProgress(progress + 40);
      setLoadingApi(true)
      promise_login.then((value) => {
          if(value.hasil == '1'){
              setProgress(100);
              setLoadingApi(false)
              alert(value.message);
              history.push('/aramex_order');
              window.location.reload(true);
          }else if(value.hasil == '2'){
              setProgress(100);
              setLoadingApi(false)
              alert('Pesan Dari Aramex : '+ value.message);
              history.push('/aramex_order');
              window.location.reload(true);
          }
      });
      
    }else{
      alert('Mohon maaf user anda tidak berwenang untuk aktif API');
    }
  }

  function resi_aramex(id){
    if(id == '0'){
      alert('Resi Tidak Tersedia');
    }else{
      setLoadingResi(true);
      setProgress(progress + 40);
      setTimeout(
          function() {
            setLoadingResi(false);
            setProgress(100);
            window.open(id);
          }
          ,
          2000
      );
    }
  }

  function resi_fastworld(id,status){
    setProgress(progress + 40);
    setLoadingResi(true);
    const promise_login = Promise.resolve(resi_fwx_aramex(id));

    promise_login.then((value) => {
        if(value.hasil == '1'){
            setProgress(100);
            setLoadingResi(false);
            window.open(value.message);
        }
    });
  }

  
  function edit_order(id){
    history.push('/aramex_edit_order?foreignhawb='+id);
  }

  
  function delete_order_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40);
      setLoading(true)
      const promise_login = Promise.resolve(aramex_delete_order(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setProgress(100);
                setLoading(false)
                alert(value.message);
                history.push('/aramex_order');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setProgress(100);
                setLoading(false)
                alert(value.message);
                history.push('/aramex_order');
                window.location.reload(true);
            }
        });
        
    } else {
    }
  }

  function create_order(foreignhawb){
    if (window.confirm('Harap Periksa Data Terlebih Dahulu Sebelum Create Order !!!!!')) {
      setProgress(progress + 40);
      setLoadingCreateOrder(true)
      const promise_login = Promise.resolve(aramex_real_order(foreignhawb));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setProgress(100);
                setLoadingCreateOrder(false)
                alert("Order Aramex Berhasil");
                history.push('/aramex_order');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setProgress(100);
                setLoadingCreateOrder(false)
                alert(value.error_message);
                history.push('/aramex_order');
                window.location.reload(true);
            }else if(value.hasil == '3'){
                setProgress(100);
                setLoadingCreateOrder(false)
                alert(value.error_message);
                history.push('/aramex_order');
                window.location.reload(true);
            }else{
              setProgress(100);
              setLoadingCreateOrder(false)
            }
        });
    } else {
      setProgress(100);
      setLoadingCreateOrder(false)
    }
  }


  return (
    <div>
    <LoadingBar
      color='#f11946'
      progress={progress}
      onLoaderFinished={() => setProgress(0)}
    />
    <Header_admin></Header_admin>
  <main id="main" class="main">

    <div class="pagetitle">
      <h1>Data Order Aramex&nbsp; <img src={require('../../../assets_landingpage/img/aramex_icon.png')} alt="Profile" width="80px" height="40px" /></h1>
      &nbsp;
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
          <li class="breadcrumb-item active">Order</li>
        </ol>
      </nav>
    </div>

    <section class="section">
      <div class="row">
        <div class="col-lg-2">
            <button className="button_tambah_item" onClick={(e) => {
                e.preventDefault();
                window.location.href='/aramex_add_order';
                }}>
              <i className="fa fa-plus"
                style={{ marginRight: "5px" }}
              />
              &nbsp;
              Add Order
            </button>
        </div>
        <div class="col-lg-4">
        Status API Aramex : {statusApiOrder == "1" ? ("Nyala"):("Mati")} &nbsp;&nbsp;&nbsp;&nbsp;
            {
              statusApiOrder == "1" ? (
                <button type="button" className="button_api_off" disabled={loadingApi} onClick={() => onoff()}>
                  {statusApiOrder == "1" ? 
                    
                      loadingApi ? 
                      (<div>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                        Matikan
                      </div>)
                      :
                      (<div>
                        <i
                        className="fa fa-power-off"
                        style={{ marginRight: "5px" }}
                      />
                      Matikan
                      </div>)
                    
                    :
                    loadingApi ? 
                      (<div>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                        Nyalakan
                      </div>)
                      :
                      (<div>
                        <i
                        className="fa fa-power-off"
                        style={{ marginRight: "5px" }}
                      />
                      Nyalakan
                      </div>)
                  }
                </button>
                )
                :
                (
                <button type="button" className="button_api_on" disabled={loadingApi} onClick={() => onoff()}>
                  {statusApiOrder == "1" ? 
                    loadingApi ? 
                    (<div>
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                      Matikan
                    </div>)
                    :
                    (<div>
                      <i
                      className="fa fa-power-off"
                      style={{ marginRight: "5px" }}
                    />
                    Matikan
                    </div>)
                    :
                    loadingApi ? 
                      (<div>
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                        Nyalakan
                      </div>)
                      :
                      (
                        <div>
                          <i
                          className="fa fa-power-off"
                          style={{ marginRight: "5px" }}
                        />
                        Nyalakan
                        </div>
                      )
                    }
                </button>
                )
            }
        </div>
        <div class="col-md-6 d-flex flex-row-reverse">
            <form class="form-inline">
            <input
              type="text"
              className="form-control"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Cari sesuatu disini..."
            />
            &nbsp;
            <button type="button" onClick={searchData} className="button_cari" disabled={loadingCari}>
              {loadingCari ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ):
              (<i
                className="fa fa-search"
                style={{ marginRight: "5px" }}
              />)}
              {loadingCari && <span>Cari</span>}
              {!loadingCari && <span>Cari</span>}
            </button>
            &nbsp;
            <button type="button" onClick={resetPencarian} className="button_reset_pencarian" disabled={loadingResetPencarian}>
              {loadingResetPencarian ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ):
              (<i
                className="fa fa-refresh"
                style={{ marginRight: "5px" }}
              />)}
              {loadingResetPencarian && <span>Reset Pencarian</span>}
              {!loadingResetPencarian && <span>Reset Pencarian</span>}
            </button>
            </form>
        </div>
      </div>
      <br />
      <div class="row"> 
        <div class="col-lg-12">
        <div>
              <table className="table table-hover table-bordered">
                  <thead>
                  <tr>
                      <th>Foreign HAWB</th>
                      <th>Detail</th>
                      <th>Status</th>
                      <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                  order != 'Data Tidak Tersedia' ?
                  order.map((order) => (
                      <tr>
                      <td>{order.foreignhawb}</td>
                      <td>
                        <button type="button" className='button_detail' disabled={loadingDetail} 
                          onClick={() => {
                            setLoadingDetail(true);
                            setProgress(progress + 40);
                            setTimeout(
                                function() {
                                  setLoadingDetail(false);
                                  setForeignHawb(order.foreignhawb);
                                  setReference(order.reference);
                                  setShipperName(order.shipper_name);
                                  setShipperCompany(order.shipper_company);
                                  setShipperPhone(order.shipper_phone);
                                  setShipperEmail(order.shipper_email);
                                  setShipperAddress(order.shipper_address);
                                  setShipperCity(order.shipper_city);
                                  setShipperPostalCode(order.shipper_postalcode);
                                  setShipperCountryCode(order.shipper_countrycode);
                                  setConsigneeName(order.consignee_name);
                                  setConsigneeCompany(order.consignee_company);
                                  setConsigneePhone(order.consignee_phone);
                                  setConsigneeEmail(order.consignee_email);
                                  setConsigneeAddress(order.consignee_address);
                                  setConsigneeCity(order.consignee_city);
                                  setConsigneePostalCode(order.consignee_postalcode);
                                  setConsigneeCountryCode(order.consignee_countrycode);
                                  setCreatedDate(order.created_date);
                                  setShow(true);
                                }
                                ,
                                2000
                            );
                          }}>
                            {loadingDetail ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-search"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loadingDetail && <span>Detail</span>}
                            {!loadingDetail && <span>Detail</span>}
                          </button>
                      </td>
                      <td>
                        {
                          order.flag_status == '1' ? (<div><img src={require('../../../assets_landingpage/img/silang.png')} width="30px" height="30px" alt="" />&nbsp;&nbsp;&nbsp;<button type="button" onClick={() => create_order(order.foreignhawb)} className="button_create_order" disabled={loadingCreateOrder}>
                            {loadingCreateOrder ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-send"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loadingCreateOrder && <span>Create Order</span>}
                            {!loadingCreateOrder && <span>Create Order</span>}  
                          </button></div>
                          )
                          :
                          (
                          <div>
                            <img src={require('../../../assets_landingpage/img/ceklist.png')} width="30px" height="30px" alt="" />
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" className="button_resi_provider" disabled={loadingResi} onClick={() => resi_aramex(order.resi_aramex)}>
                              {loadingResi ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-paperclip"
                                style={{ marginRight: "5px" }}
                              />)}
                              {loadingResi && <span>Resi Aramex</span>}
                              {!loadingResi && <span>Resi Aramex</span>}
                            </button>
                            &nbsp;
                            {
                              <button type="button" className="button_resi_fastworld" disabled={loadingResi} onClick={() => resi_fastworld(order.foreignhawb,0)}>
                                {loadingResi ? (
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                  />
                                ):
                                (<i
                                  className="fa fa-paperclip"
                                  style={{ marginRight: "5px" }}
                                />)}
                                {loadingResi && <span>Resi Fastworld</span>}
                                {!loadingResi && <span>Resi Fastworld</span>}
                              </button>
                            }
                            
                          </div>
                          )
                        }
                        
                      </td>
                      <td>
                        <div class="w3-show-inline-block">
                          <div class="w3-bar">
                            <button type="button" className="button_edit" disabled={loading} onClick={() => edit_order(order.foreignhawb)}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-edit"
                                style={{ marginRight: "5px" }}
                              />)}
                              {loading && <span>Edit</span>}
                              {!loading && <span>Edit</span>}
                            </button>&nbsp;
                            <button type="button" className="button_hapus" disabled={loading} onClick={() => delete_order_onclick(order.foreignhawb)}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-times-circle"
                                style={{ marginRight: "5px" }}
                              />)}
                              {loading && <span>Hapus</span>}
                              {!loading && <span>Hapus</span>}
                            </button>
                          </div>
                        </div>
                      </td>
                      </tr>
                  ))
                  :
                  <tr>
                      <td colSpan={5}>Data Tidak Tersedia</td>
                  </tr>
                  }
                  </tbody>
              </table>
              <p>
                  Total Rows: {rows} Page: {page} of {pages}
              </p>
              <p className="has-text-centered has-text-danger">{msg}</p>

              <div id="container">
                  <ReactPaginate
                      previousLabel={"< Prev"}
                      nextLabel={"Next >"}
                      pageCount={pages}
                      onPageChange={changePage}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={'active'}
                  />
              </div>
          </div>

        </div>
      </div>
    </section>

  </main>
  <Modal size="lg" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Data Order</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form class="row g-3 needs-validation" novalidate>
      <div class="row">
        <div class="col-12">
          <label for="lTrackingNumber" class="form-label">Foreign HAWB</label>
          <div class="input-group has-validation">
            <input type="text" name="foreignhawb" id="foreignhawb" class="form-control" value={foreignHawb} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Created Date</label>
          <div class="input-group has-validation">
            <input type="date" name="created_date" id="created_date" class="form-control" value={createdDate} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Name</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_name" id="shipper_name" class="form-control" value={shipperName} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Company</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_company" id="shipper_company" class="form-control" value={shipperCompany} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Phone</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_phone" id="shipper_phone" class="form-control" value={shipperPhone} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Email</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_email" id="shipper_email" class="form-control" value={shipperEmail} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Address</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_address" id="shipper_address" class="form-control" value={shipperAddress} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper City</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_city" id="shipper_city" class="form-control" value={shipperCity} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Postalcode</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_postalcode" id="shipper_postalcode" class="form-control" value={shipperPostalCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Shipper Country Code</label>
          <div class="input-group has-validation">
            <input type="text" name="shipper_countrycode" id="shipper_countrycode" class="form-control" value={shipperCountryCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Name</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_name" id="consignee_name" class="form-control" value={consigneeName} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Company</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_company" id="consignee_company" class="form-control" value={consigneeCompany} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Phone</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_phone" id="consignee_phone" class="form-control" value={consigneePhone} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Email</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_email" id="consignee_email" class="form-control" value={consigneeEmail} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Address</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_address" id="consignee_address" class="form-control" value={consigneeAddress} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee City</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_city" id="consignee_city" class="form-control" value={consigneeCity} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Postalcode</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_postalcode" id="consignee_postalcode" class="form-control" value={consigneePostalCode} required />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="lLength" class="form-label">Consignee Country Code</label>
          <div class="input-group has-validation">
            <input type="text" name="consignee_countrycode" id="consignee_countrycode" class="form-control" value={consigneeCountryCode} required />
          </div>
        </div>
      </div>
    </form>
    </Modal.Body>
  </Modal>
  <Footer_admin></Footer_admin>
    </div>
  );
}

export default Order;