import React from 'react';

function TableRows({rowsData, deleteTableRows, handleChange}) {
    return(
        
        rowsData.map((data, index)=>{
            const {description, qom, quantity, unit_price, weight, mcc, length, width, height}= data;
            return(
                <tr key={index}>
                <td>
                    <div class="row mb-1">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Item Description</label>
                            </div>
                            <div class="col-md-8">
                            <input type="text" value={description}  onChange={(evnt)=>(handleChange(index, evnt))} name="description" className="form-control" />
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Quantity Of Measurement</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={qom} onChange={(evnt)=>(handleChange(index, evnt))} name="qom" className="form-control"/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Quantity</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={quantity}  onChange={(evnt)=>(handleChange(index, evnt))} name="quantity" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Unit Price</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={unit_price}  onChange={(evnt)=>(handleChange(index, evnt))} name="unit_price" className="form-control"/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Weight</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={weight}  onChange={(evnt)=>(handleChange(index, evnt))} name="weight" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Manufacturing Country Code</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={mcc}  onChange={(evnt)=>(handleChange(index, evnt))} name="mcc" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Length</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={length}  onChange={(evnt)=>(handleChange(index, evnt))} name="length" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Width</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={width}  onChange={(evnt)=>(handleChange(index, evnt))} name="width" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Height</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={height}  onChange={(evnt)=>(handleChange(index, evnt))} name="height" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>HAPUS</label>
                            </div>
                            <div class="col-md-8">
                                <button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </td>
                
            </tr>
            )
        })
   
    )
    
}
export default TableRows;