import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { add_type_item } from '../../../Context/actions';
import { delete_type_item } from '../../../Context/actions';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

function Country() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const history = useHistory();

  const [namaTypeItem, setNamaTypeItem] = useState();
  

  const [typeItem, setTypeItem] = useState([]);

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");


  useEffect(() => {
      let url;
      const fetchData = async () => {
      if(query == ''){
          url = 'https://athaexpress.net/backend/ongkir/api/showTypeItemGetDataPagination';
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          
          if(json.status == "true"){
              setPage(page);
              setPages(json.pages);
              setRows(json.rows);
          }
          
          };

          
      }

      fetchData();
  }, [page, keyword]);

  useEffect(() => {
      let url;
      setProgress(progress + 40);
      if(query == ''){
          if(awal == undefined){
              url = 'https://athaexpress.net/backend/ongkir/api/showTypeItemPagination/0';
          }else{
              url = 'https://athaexpress.net/backend/ongkir/api/showTypeItemPagination/'+awal;
          }

          const fetchData = async () => {
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setTypeItem(json.data);
          }
          
          };
          fetchData();
      }
    },[]);

  const changePage = ({ selected }) => {
      setProgress(progress + 40);
      if(query == ''){
          setPage(selected+1);
          
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);

          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showTypeItemPagination/'+awal,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setTypeItem([]);
              setTypeItem(json.data);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }else{
          setPage(selected+1);
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          
          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showTypeItemPaginationSearch/'+awal+'/'+query,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setTypeItem([]);
              setTypeItem(json.data);
              
              setPages(json.pages);
              setRows(json.rows);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }
      
  };

  function searchData(){
      setProgress(progress + 40);
      setLoadingCari(true)
      const fetchData = async () => { 
      const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/showTypeItemPaginationSearch/0/'+query,
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setLoadingCari(false)
          setTypeItem([]);
          setPages(0);
          setRows(0);
          setPage(1);
          setTypeItem(json.data);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
          setProgress(100);
          setLoadingCari(false)
          setTypeItem(json.data);
          setPages(json.pages);
          setRows(json.rows);
          setMsg(
              "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country!"
          );
      }
      
      };
      fetchData(); 
  }; 

  function resetPencarian(){
      setLoadingCari(true)
      setLoadingResetPencarian(true)
      setQuery("");
      setTimeout(
          function() {
            setLoadingCari(false)
            setLoadingResetPencarian(false)
            window.location.reload(true);
          }
          ,
          2000
      );
  }

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://athaexpress.net/backend/ongkir/api/showTypeItem',
  //     );
  //     const json = await res.json();
  //     if(json.status == "true"){
  //       setLoading(false);
  //       setTypeItem(json.data);
  //     }else{
  //       setLoading(false);
  //       setTypeItem("Data Tidak Tersedia");
  //     }
      
      
  //   };
  //   fetchData();
    
  // },[]);

  
  // useEffect(() => {
  //     setTimeout(()=>{
  //       $("#example2").DataTable({
  //           destroy: true,
  //           //dom: "rBftlip",
  //           // buttons: [
  //           //   {

  //           //   },
  //           // ],
  //           lengthMenu: [
  //             [10, 20, 50, 100, -1],
  //             [10, 20, 50, 100, "All"],
  //           ],
  //           pageLength: 10,
  //       });
  //     },1000)
  // }, [])
  
  function edit_type_item(id){
    history.push('/edit_type_item?id='+id);
  }

  
  function delete_type_item_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40)
      setLoading(true)
      const promise_login = Promise.resolve(delete_type_item(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setProgress(100)
                setLoading(false)
                alert(value.message);
                history.push('/type_item');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setProgress(100)
                setLoading(false)
                alert(value.message);
                history.push('/type_item');
                window.location.reload(true);
            }
        });
        
    } else {
      // Do nothing!
    }
  }

  function goToAddTypeItem(){
    window.location = '/add_type_item';
  }

  return (
 
          <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            /> 
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1 class="mb-2">Data Type Item</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item active">Type Item</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-3">
                      <button className="button_tambah_item" onClick={goToAddTypeItem}>
                        <i className="fa fa-plus"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp;
                        Add Type Item
                      </button>
                  </div>
                  <div class="col-md-9 d-flex flex-row-reverse">
                      <form class="form-inline">
                      <input
                        type="text"
                        className="form-control"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Cari sesuatu disini..."
                      />
                      &nbsp;
                      <button type="button" onClick={searchData} className="button_cari" disabled={loadingCari}>
                        {loadingCari ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-search"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingCari && <span>Cari</span>}
                        {!loadingCari && <span>Cari</span>}
                      </button>
                      &nbsp;
                      <button type="button" className="button_reset_pencarian" onClick={resetPencarian} disabled={loadingCari}>
                        {loadingResetPencarian ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-refresh"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingResetPencarian && <span>Reset Pencarian</span>}
                        {!loadingResetPencarian && <span>Reset Pencarian</span>}
                      </button>
                      </form>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-lg-12">
                  <div>
                      <table className="table table-hover table-bordered">
                          <thead>
                          <tr>
                              <th>Name</th>
                              <th>Price Delivery</th>
                              <th>Country</th>
                              <th>Type Price</th>
                              <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                          typeItem != 'Data Tidak Tersedia' ?
                          typeItem.map((typeItem) => (
                              <tr>
                              <td>{typeItem.name}</td>
                              <td>{typeItem.price_delivery}</td>
                              <td>{typeItem.country}</td>
                              <td>{typeItem.type_price}</td>
                              <td>
                                  <div class="w3-show-inline-block">
                                    <div class="w3-bar">
                                      <button type="button" class="button_edit" onClick={() => edit_type_item(typeItem.id)} disabled={loading}>
                                        {loading ? (
                                          <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                          />
                                        ):
                                        (<i
                                          className="fa fa-edit"
                                          style={{ marginRight: "5px" }}
                                        />)} &nbsp;
                                        {loading && <span>Edit</span>}
                                        {!loading && <span>Edit</span>}
                                      </button>&nbsp;
                                      <button type="button" class="button_hapus" onClick={() => delete_type_item_onclick(typeItem.id)} disabled={loading}>
                                        {loading ? (
                                          <i
                                            className="fa fa-refresh fa-spin"
                                            style={{ marginRight: "5px" }}
                                          />
                                        ):
                                        (<i
                                          className="fa fa-times-circle"
                                          style={{ marginRight: "5px" }}
                                        />)} &nbsp;
                                        {loading && <span>Hapus</span>}
                                        {!loading && <span>Hapus</span>}
                                      </button>
                                    </div>
                                  </div>
                              </td>
                              </tr>
                          ))
                          :
                          <tr>
                              <td colSpan={5}>Data Tidak Tersedia</td>
                          </tr>
                          }
                          </tbody>
                      </table>
                      <p>
                          Total Rows: {rows} Page: {page} of {pages}
                      </p>
                      <p className="has-text-centered has-text-danger">{msg}</p>

                      <div id="container">
                          <ReactPaginate
                              previousLabel={"< Prev"}
                              nextLabel={"Next >"}
                              pageCount={pages}
                              onPageChange={changePage}
                              breakClassName={'page-item'}
                              breakLinkClassName={'page-link'}
                              containerClassName={'pagination'}
                              pageClassName={'page-item'}
                              pageLinkClassName={'page-link'}
                              previousClassName={'page-item'}
                              previousLinkClassName={'page-link'}
                              nextClassName={'page-item'}
                              nextLinkClassName={'page-link'}
                              activeClassName={'active'}
                          />
                      </div>
                  </div>
                  </div>
                </div>
              </section>
            </main>
            <Footer_admin></Footer_admin>

            </div>
        
  );
}

export default Country;