import React, {Component} from 'react';
export default class Footer_admin extends Component {
    render(){
        return (
            <footer id="footer" class="footer">
                <div class="copyright">
                &copy; Copyright 2022 <strong><span>Fast World Express</span></strong>. All Rights Reserved
                </div>
            </footer>
        )
    }
}