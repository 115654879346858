import React from 'react';

function TableRows({rowsData, deleteTableRows, handleChange}) {
    return(
        
        rowsData.map((data, index)=>{
            const {package_type, quantity, weight, comment}= data;
            return(
                <tr key={index}>
                <td>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Package Type</label>
                            </div>
                            <div class="col-md-8">
                            <input type="text" value={package_type}  onChange={(evnt)=>(handleChange(index, evnt))} name="package_type" className="form-control" />
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Quantity</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={quantity} onChange={(evnt)=>(handleChange(index, evnt))} name="quantity" className="form-control"/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Weight</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={weight}  onChange={(evnt)=>(handleChange(index, evnt))} name="weight" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Comment</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={comment}  onChange={(evnt)=>(handleChange(index, evnt))} name="comment" className="form-control"/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>HAPUS</label>
                            </div>
                            <div class="col-md-8">
                                <button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </td>
                
            </tr>
            )
        })
   
    )
    
}
export default TableRows;