import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import {Modal, Button, Form} from 'react-bootstrap';
import Select from "react-select";

export default function Home() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  // const handleonSubmit = async e => {
  //   e.preventDefault();
  // }

  const history = useHistory();

  const [fromSearch, setFormSearch] = useState();
  const [country, setCountry] = useState([]);


  const [idFrom, setIdFrom] = useState();
  const [idCountryHome, setIdCountryHome] = useState();
  const [weight, setWeight] = useState();

  useEffect(() => {
    fetch("https://athaexpress.net/backend/ongkir/api/showFrom")
    .then((res) => res.json())
    .then((data) => {
      const userData = data.data.map((item) => ({
        label: item.kota, // <-- input values you are matching
        value: item.id
      }));
      setFormSearch(userData);
    });
    
  },[]);

  useEffect(() => {
    fetch("https://athaexpress.net/backend/ongkir/api/showCountry")
    .then((res) => res.json())
    .then((data) => {
      const userData = data.data.map((item) => ({
        label: item.nama, // <-- input values you are matching
        value: item.id
      }));
      setCountry(userData);
    });
    
  },[]);

  function trackNow(){
      var id = document.getElementById("parameter_tracking").value;
      history.push('/tracking?id='+id);
  }

  function cekTarif(){
      var from = idFrom.idFrom.label;
      var destination = idCountryHome.idCountryHome.label;
      var weight = document.getElementById("weight").value;
      console.log(from,destination,weight)
      history.push('/cektarif?country='+ destination +'&weight=' + weight +'&from=' + from);
      //window.location.reload(true);
  }

  const handleChangeFrom = (idFrom) => {
    setIdFrom({ idFrom });
  }

  const handleChangeCountry = (idCountryHome) => {
    setIdCountryHome({ idCountryHome });
  }
  

  return(
    <div>
      <div id="header_landingpage">    
        <div class="center text-center">
            <div class="navigation">
              <div class="nav-container">
                <div class="brand">
                  <img class="left-img" src={require('../../assets_landingpage/img/fasworld.jpg')} width="110px" height="60px" alt="" />
                </div>
                <nav class="nav__atas">
                  <div class="nav-mobile">
                    <a id="nav-toggle" href="#"><span></span></a>
                  </div>
                  <ul class="nav-list">
                    <li><Link to="/halaman_utama">Beranda</Link></li>
                    <li><Link to="/portofolio">Portofolio</Link></li>
                    <li><Link to="/superiority">Keunggulan</Link></li>
                    <li><Link to="/kontak">Kontak</Link></li>
                    <li><Link to="/termsandcondition">Syarat dan Ketentuan</Link></li>
                    
                  </ul>
                </nav>
              </div>
            </div>
            <div class="banner">
            
              <div class="tracking">
                <div class="title-tracking">
                  Selamat Datang Pelanggan Fast World Express
                </div>
                <div class="body-tracking">
                  <form>
                    <input type="text" class="tracking-form" name="parameter_tracking" id="parameter_tracking" placeholder="Ketik nomor resi di sini" />
                    <button type="button" class="tracking-btn" onClick={() => trackNow()}>Telusuri</button>
                  </form>
                </div>
                
                <div class="left-tracking hide-mobile">
                  {/* <img class="left-img" src={require('../../assets_landingpage/img/left-tracking.gif')} alt="" /> */}
                </div>
                <div class="right-tracking">
                  {/* <img src={require('../../assets_landingpage/img/left-tracking.gif')} alt="" /> */}
                </div>
                {/* <div class="land"></div> */}
              </div>
            </div>
        </div>
      </div>
      <div id="quick-menu">   
        <div class="container_box">
            <ul>
              <li class="box-menu" onClick={handleShow}>
                <div class="box-wrap">
                  <div class="mobile">
                    <div class="title-mobile"><i class="bi bi-calculator-fill iconnic"></i><br />Tarif</div>
                  </div>
                  <div class="web">
                    <div class="title">Cek Tarif &nbsp; &nbsp; &nbsp; <i class="bi bi-calculator-fill iconnic"></i></div>
                    <div class="desc"></div>
                  </div>
                </div>
              </li>
              <li class="box-menu">
                <Link to="/services" class="link_layanan">
                  <div class="box-wrap">
                  <div class="mobile">
                    <i class="icon-calculation"></i>
                    <div class="title-mobile"><i class="bi bi-award-fill iconnic"></i><br />Layanan</div>
                  </div>
                  <div class="web">
                    <div class="title">Layanan &nbsp; &nbsp; &nbsp; <i class="bi bi-award-fill iconnic"></i></div>
                    <div class="desc"></div>
                  </div>
                  </div>
                </Link>
              </li>
              <li class="box-menu">
                <Link to="/kontak" class="link_kontak">
                  <div class="box-wrap">
                    <div class="mobile">
                      <i class="icon-calculation"></i>
                      <div class="title-mobile"><i class="bi bi-chat-quote-fill iconnic"></i><br />Kontak</div>
                    </div>
                    <div class="web">
                      <div class="title">Kontak &nbsp; &nbsp; &nbsp;<i class="bi bi-chat-quote-fill iconnic"></i></div>
                      <div class="desc"></div>
                    </div>
                  </div>
                </Link>
              </li>
            </ul> 
        </div>
      </div>
      
      <div id="story" class="light-wrapper">
        {/* <section class="ss-style-top"></section> */}
        <div class="container inner">
            <h2 class="section-title text-left">Cheap Express Overseas Freight Forwarding Service</h2>
            <p class="lead main text-left">Express delivery of goods and documents overseas such as DHL and Fedex at low rates. Expedition Package delivery services, Food, Cosmetics, Herbs, Samples, Furniture, Furniture, Crafts, Cigarettes, Coffee, Moving abroad at low rates. In addition, we also provide international air cargo shipping services from Indonesia to all over the world.</p>
            <p class="lead main text-left">0821-1370-5220</p>
            {/* <div class="row text-center story">
                <div class="col-sm-4">
                    <div class="col-wrapper">
                        <div class="icon-wrapper"> <i class="fa fa-anchor"></i> </div>
                        <h3>EST. 2013</h3>
                        <p>We're the fastest growing fast food chain in Brazil since 2013.</p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="col-wrapper">
                        <div class="icon-wrapper"> <i class="fa  fa-cutlery"></i> </div>
                        <h3>Cooking Traditions</h3>
                        <p>American burger tradition fused with world famous Brazilian "Churrasco". </p>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="col-wrapper">
                        <div class="icon-wrapper"> <i class="fa fa-coffee"></i> </div>
                        <h3>Food Quality</h3>
                        <p>Award winning best sauce of the year 2 times in a row. 'Nough said!.</p>
                    </div>
                </div>
            </div> */}
            <Link to="/termsandcondition" class="button button1">TERMS AND CONDITION</Link>
            <br />
            <hr />
            <br />
            <h2 class="section-title text-left">Do you need Mail and Package Forwarding Service in Indonesia?</h2>
            <img class="left-img" src={require('../../assets_landingpage/img/indo2ship.png')} width="350px" height="200px" alt="" />
            <p class="lead main text-left">Shop and Ship with safe and no worry free in Indonesia. Get your free Indonesia address and start Shopping to multiple retailer, marketplace, supplier, distributors or factory in Indonesia. At this time, We are the first in Indonesia and with most completed services over the world.

            Please continue to Shipfromindo.com or click this button</p>
            

            <a href="https://shipfromindo.com" class="button button1" target="_blank" rel="noreferrer">
            SHOP AND SHIP NOW
            </a>
            <br />
            <hr />
            <br />
            <h2 class="section-title text-left">Jasa Pengiriman Barang ke Luar Negeri Kilat Murah Fast World Express</h2>
            <p class="lead main text-left">
            <img class="left-img" src={require('../../assets_landingpage/img/intermezo.png')} width="350px" height="200px" alt="" /><br />
            
            Fast World Express merupakan Ekspedisi Jasa  Pengiriman Paket, Dokumen dan Cargo ke Luar Negeri Kilat dengan tarif murah. Dimana, khusus melayani kebutuhan pengiriman internasional <b><i>kilat (express)</i></b>. Dalam hal ini, yang mengutamakan kecepatan, keamanan dan service. Baik, untuk pelanggan individu maupun perusahaan.

            Kami berbeda dengan jasa pengiriman luar negeri lain, seperti yang banyak kita jumpai di google. Sebagai contoh, banyak ekspedisi yang tarif pengirimannya murah, namun tanpa ada jaminan keamanan dan kecepatan waktu. Bahkan, kebanyakan dengan service yang ala kadarnya. 

            Oleh karena itu, jika anda ingin mengetahui service dan layanan kami. Maka, anda bisa melihat service jasa pengiriman internasional kelas premium di Indonesia. Misalnya : Jasa pengiriman barang ke luar negeri DHL, Fedex, TNT dan UPS. 

            Dengan penuh bangga, kami sampaikan. Bahwa, Fast World Express, merupakan salah satu anak Perusahaan dari Jasa Pengiriman dan Logistic Internasional <b><i>ATHA EXPRESS GROUP</i></b>. Yang khusus, melayani pengiriman dokumen dan paket kilat dari Indonesia ke luar negeri, dengan tarif murah dan terjangkau bagi masyarakat Indonesia.</p>
        </div>
        {/* <section class="ss-style-bottom"></section> */}
      </div>

      <div id="footer_landingpage" class="dark-wrapper">
          <div class="container inner">
              <div class="row logo_footer">
                  <div class="col-sm-12">
                      Fast World Express
                  </div>
              </div>
              <div class="row alamat">
                  <div class="col-sm-12">
                      Wiyung Indah XVIII NO 16 A,<br />
                      Jawa Timur, Kota Surabaya, Kecamatan Wiyung.
                  </div>
              </div>
              <div class="row social">
                  <div class="col-sm-12">
                      <div class="social-bar">
                          <a href="#" class="fa fa-instagram tooltipped" title="instagram"></a>
                          <a href="#" class="fa fa-youtube-square tooltipped" title="youtube"></a>
                          <a href="#" class="fa fa-facebook-square tooltipped" title="facebook"></a>
                          <a href="#" class="fa fa-twitter-square tooltipped" title="twitter"></a>
                          <a href="#" class="fa fa-google-plus-square tooltipped" title="google+"></a>
                      </div>
                  </div>
              </div>
              <div class="row copyright">
                  <div class="col-sm-12">
                      &copy; Copyright Fast World Express 2022
                  </div>
              </div>
          </div>
      </div>
      <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Cek Tarif</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <br />
            <form class="row g-3 needs-validation">
            <div class="row">
              <div class="col-12">
                <label for="yourUsername" class="form-label">Asal</label>
                <div class="input-group has-validation">
                  <div style={{width: '100%'}}>
                    <Select
                      options={fromSearch}
                      //value={idCountryPrice} 
                      onChange={handleChangeFrom}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="yourUsername" class="form-label">Tujuan</label>
                <div class="input-group has-validation">
                  <div style={{width: '100%'}}>
                    <Select
                      options={country}
                      //value={idCountryPrice} 
                      onChange={handleChangeCountry}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="yourUsername" class="form-label">Berat/Kg</label>
                <div class="input-group has-validation">
                  <input type="number" name="weight" id="weight" class="form-control" value={weight} onChange={(e) => setWeight(e.target.value)} required />
                  <div class="invalid-feedback">Masukkan Price Delivery</div>
                </div>
              </div>
            </div>
              <div class="row"><div class="col-12"><hr /></div></div>
              <div class="row">
              <div class="col-6">
                <button class="btn btn-primary w-100" type="button" onClick={() => cekTarif()}>Cek Tarif</button>
               
              </div>
              <div class="col-6">
                <button class="btn btn-secondary w-100" onClick={handleClose}>Close</button>
              </div>
              </div>
              
             
            </form>
            </Modal.Body>
          </Modal>
    </div>
    
  );
}