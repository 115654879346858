import React, {useEffect, useState} from 'react';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { Link } from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import { useHistory } from 'react-router';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Sidebar_admin from '../../../Layout/Sidebar_admin';

import { dhl_order } from '../../../Context/actions';

import LoadingSpinner from "../../loading/LoadingSpinner";
import LoadingBar from 'react-top-loading-bar';


function Add_order() {
    const history = useHistory();

    
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [shipmentInfoAccount, setShipmentInfoAccount] = useState();
    const [shipmentDate, setShipmentDate] = useState();
    const [shipmentInfoServiceType, setShipmentInfoServiceType] = useState();
    const [shipmentInfoCurrency, setShipmentInfoCurrency] = useState();
    const [shipmentInfoPaymentInfo, setShipmentInfoPaymentInfo] = useState();
    const [commoditiesNumberOfPieces, setCommoditiesNumberOfPieces] = useState();
    const [commoditiesDescription, setCommoditiesDescription] = useState();
    const [commoditiesCom, setCommoditiesCom] = useState();
    const [commoditiesQuantity, setCommoditiesQuantity] = useState();
    const [commoditiesUnitPrice, setCommoditiesUnitPrice] = useState();
    const [commoditiesCustomValue, setCommoditiesCustomValue] = useState();
    const [recipientName, setRecipientName] = useState();
    const [recipientCompany, setRecipientCompany] = useState();
    const [recipientPhone, setRecipientPhone] = useState();
    const [recipientEmail, setRecipientEmail] = useState();
    const [recipientAddress, setRecipientAddress] = useState();
    const [recipientPostalCode, setRecipientPostalCode] = useState();
    const [recipientCountry, setRecipientCountry] = useState();
    const [recipientCity, setRecipientCity] = useState();
    const [shipperName, setShipperName] = useState();
    const [shipperCompany, setShipperCompany] = useState();
    const [shipperPhone, setShipperPhone] = useState();
    const [shipperEmail, setShipperEmail] = useState();
    const [shipperAddress, setShipperAddress] = useState();
    const [shipperPostalCode, setShipperPostalCode] = useState();
    const [shipperCountry, setShipperCountry] = useState();
    const [shipperCity, setShipperCity] = useState();
    const [exportDeclarationInvoiceNumber, setExportDeclarationInvoiceNumber] = useState();
    const [exportDeclarationDestinationPort, setExportDeclarationDestinationPort] = useState();

    const [idCurrency, setIdCurrency] = useState([]);
    const [selectCurrency, setSelectCurrency] = useState([]);
    const [idCountry, setIdCountry] = useState([]);
    const [idCountryRecipient, setIdCountryRecipient] = useState([]);
    const [idCountryShipper, setIdCountryShipper] = useState([]);
    const [selectCountry, setSelectCountry] = useState([]);
    const [idCom, setIdCom] = useState([]);
    const [idServiceType, setIdServiceType] = useState([]);
    const [selectServiceType, setSelectServiceType] = useState([]);
    const [idPaymentInfo, setIdPaymentInfo] = useState([]);
    const [selectPaymentInfo, setSelectPaymentInfo] = useState([]);


    const [rowsData, setRowsData] = useState([]);
 
    //Currency Code
    useEffect(() => {
      fetch("https://athaexpress.net/backend/ongkir/api/showCurrencyDhl")
      .then((res) => res.json())
      .then((data) => {
        const dataCurrency = data.data.map((item) => ({
          label: item.currency_name, // <-- input values you are matching
          value: item.currency_code
        }));
        setSelectCurrency(dataCurrency);
      });
    },[]);

    //Country Code
    useEffect(() => {
      fetch("https://athaexpress.net/backend/ongkir/api/showCountryDhl")
      .then((res) => res.json())
      .then((data) => {
        const dataCountry = data.data.map((item) => ({
          label: item.country_name, // <-- input values you are matching
          value: item.country_code
        }));
        setSelectCountry(dataCountry);
        setSelectCountry(dataCountry);
      });
    },[]);

    //ServiceType Code
    useEffect(() => {
      fetch("https://athaexpress.net/backend/ongkir/api/showServiceTypeDhl")
      .then((res) => res.json())
      .then((data) => {
        const dataServiceType = data.data.map((item) => ({
          label: item.service_name, // <-- input values you are matching
          value: item.service_code
        }));
        setSelectServiceType(dataServiceType);
      });
    },[]);

    useEffect(() => {
      let paymentInfo = [{
          label: 'Ex Works (EXW)',
          value: 'EXW'
        },{
          label: 'Free Carrier (FCA)',
          value: 'FCA'
        },{
          label: 'Carriage Paid To (CPT)',
          value: 'CPT'
        },{
          label: 'CFR Cost and Freight (CFR)',
          value: 'CFR'
        },{
          label: 'Carriage and Insurance Paid To (CIP)',
          value: 'CIP'
        },{
          label: 'CIF Cost, Insurance and Freight (CIF)',
          value: 'CIF'
        },{
          label: 'Delivered At Terminal (DAT)',
          value: 'DAT'
        },{
          label: 'Delivered At Place (DAP)',
          value: 'DAP'
        },{
          label: 'Delivered Duty Paid (DDP)',
          value: 'DDP'
        },{
          label: 'FAS Free Alongside Ship (FAS)',
          value: 'FAS'
        },{
          label: 'FOB Free On Board (FOB)',
          value: 'FOB'
        }];

        setSelectPaymentInfo(paymentInfo)
    },[]);

    const addTableRows = ()=>{
  
        const rowsInput={
          description:'',
          qom:'',
          quantity:'',
          unit_price:'',
          weight:'',
          mcc:'',
          length:'',
          width:'',
          height:''
        } 
        setRowsData([...rowsData, rowsInput])
      
    }
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }
 
    const handleChange = (index, evnt)=>{
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }


    function simpan(){
        //console.log(rowsData)
      setShipperName('ATHA EXPRESS');
      setShipperEmail('atha@gmail.com')
      setShipperPhone('083833555600');
      setShipperCity('JAKARTA');
      setShipperPostalCode('12740');
      setShipperCountry('ID');
      setShipperAddress('KALIBATA TIMUR I NO 19');

      setProgress(progress + 40)
      setLoading(true)

      console.log(shipmentDate,idServiceType.idServiceType.value,idServiceType.idServiceType.label,idCurrency.idCurrency.value,idCurrency.idCurrency.label,idPaymentInfo.idPaymentInfo.value,idPaymentInfo.idPaymentInfo.label,commoditiesNumberOfPieces,commoditiesDescription,idCom.idCountry.value,idCom.idCountry.label,commoditiesQuantity,commoditiesUnitPrice,commoditiesCustomValue,recipientName,recipientCompany,recipientPhone,recipientEmail,recipientAddress,recipientPostalCode,idCountryRecipient.idCountryRecipient.value,idCountryRecipient.idCountryRecipient.label,recipientCity,shipperName,shipperCompany,shipperPhone,shipperEmail,shipperAddress,shipperPostalCode,idCountryShipper.idCountryShipper.value,idCountryShipper.idCountryShipper.label,shipperCity,exportDeclarationDestinationPort,exportDeclarationInvoiceNumber)


      const promise1 = Promise.resolve(dhl_order(shipmentDate,idServiceType.idServiceType.value,idServiceType.idServiceType.label,idCurrency.idCurrency.value,idCurrency.idCurrency.label,idPaymentInfo.idPaymentInfo.value,idPaymentInfo.idPaymentInfo.label,commoditiesNumberOfPieces,commoditiesDescription,idCom.idCountry.value,idCom.idCountry.label,commoditiesQuantity,commoditiesUnitPrice,commoditiesCustomValue,recipientName,recipientCompany,recipientPhone,recipientEmail,recipientAddress,recipientPostalCode,idCountryRecipient.idCountryRecipient.value,idCountryRecipient.idCountryRecipient.label,recipientCity,shipperName,shipperCompany,shipperPhone,shipperEmail,shipperAddress,shipperPostalCode,idCountryShipper.idCountryShipper.value,idCountryShipper.idCountryShipper.label,shipperCity,exportDeclarationDestinationPort,exportDeclarationInvoiceNumber,rowsData));
      promise1.then((value) => {
        if(value.hasil == '1'){
            //sukses
            setProgress(100);
            setLoading(false);
            alert(value.message);
            history.push('/dhl_order');
            window.location.reload(true);
        }else if(value.hasil == '2'){
            //username password salah
            setProgress(100);
            setLoading(false);
            alert(value.message);
            history.push('/dhl_order');
            window.location.reload(true);
        }else if(value.hasil == '3'){
          //username password salah
          setProgress(100);
          setLoading(false);
          alert(value.message.errorInfo[2]);
          history.push('/dhl_order');
          window.location.reload(true);
        }
      });
    }
   

    

    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
    };

    const form_order = {
      color: "#061C54",
      fontStyle: "bold"
    };

    const handleChangeCurrency = (idCurrency) => {
      setIdCurrency({ idCurrency });
    }

    const handleChangeCountryRecipient = (idCountryRecipient) => {
      setIdCountryRecipient({ idCountryRecipient });
    }

    const handleChangeCountryShipper = (idCountryShipper) => {
      setIdCountryShipper({ idCountryShipper });
    }

    const handleChangeCom = (idCountry) => {
      setIdCom({ idCountry });
    }

    const handleChangePaymentInfo = (idPaymentInfo) => {
      setIdPaymentInfo({ idPaymentInfo });
    }

    const handleChangeServiceType = (idServiceType) => {
      setIdServiceType({ idServiceType });
    }

  return (
      <div>
          <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <Header_admin></Header_admin>
          <main id="main" class="main">

            <div class="pagetitle">
              <h1>DHL Order &nbsp; <img src={require('../../../assets_landingpage/img/dhl_icon.png')} alt="Profile" width="70px" height="50px" class="rounded-circle" /></h1>
              <br />
              <nav>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li class="breadcrumb-item active">DHL Order</li>
                </ol>
              </nav>
            </div>

            <section class="section">
            <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Shipment Info</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Shipment Date</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <input type="datetime-local" id="shipmentDate" name="shipmentDate" onChange={(e) => setShipmentDate(e.target.value)} class="form-control" />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Currency</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <Select
                              options={selectCurrency}
                              onChange={handleChangeCurrency}
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Payment Info</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <Select
                              options={selectPaymentInfo}
                              onChange={handleChangePaymentInfo}
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Service Type</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <Select
                              options={selectServiceType}
                              onChange={handleChangeServiceType}
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="row mb-3 align-items-center">
                    <h4 style={style_pembatas}>Commodities</h4>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Number Of Pieces</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="numberOfPieces" name="numberOfPieces" onChange={(e) => setCommoditiesNumberOfPieces(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Description</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="description" name="description" onChange={(e) => setCommoditiesDescription(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Country Of Manufacture</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <div style={{width: '100%'}}>
                              <Select
                                options={selectCountry}
                                onChange={handleChangeCom}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Quantity</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <input type="number" id="quantity" name="quantity" onChange={(e) => setCommoditiesQuantity(e.target.value)} class="form-control" />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Unit Price</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <input type="number" id="unitPrice" name="unitPrice" onChange={(e) => setCommoditiesUnitPrice(e.target.value)} class="form-control" />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Custom Value</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <input type="number" id="custom_value" name="custom_value" onChange={(e) => setCommoditiesCustomValue(e.target.value)} class="form-control" />
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Recipient</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Name</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="recipient_name" name="recipient_name" onChange={(e) => setRecipientName(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Company</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="recipient_company" name="recipient_company" onChange={(e) => setRecipientCompany(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="recipient_phone" name="recipient_phone" onChange={(e) => setRecipientPhone(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Email</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="recipient_email" name="recipient_email" onChange={(e) => setRecipientEmail(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Address</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="recipient_address1" name="recipient_address1" onChange={(e) => setRecipientAddress(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Postal Code</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="recipient_postalcode" name="recipient_postalcode" onChange={(e) => setRecipientPostalCode(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Country</label>
                      </div>
                      <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <Select
                              options={selectCountry}
                              onChange={handleChangeCountryRecipient}
                            />
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>City</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="recipient_city" name="recipient_city" onChange={(e) => setRecipientCity(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
              </div>
              
              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Shipper</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Name</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_name" name="shipper_name" onChange={(e) => setShipperName(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Company</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="shipper_company" name="shipper_company" onChange={(e) => setShipperCompany(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_phone" name="shipper_phone" onChange={(e) => setShipperPhone(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Email</label>
                        </div>
                        <div class="col-md-8">
                        <input type="text" id="shipper_email" name="shipper_email" onChange={(e) => setShipperEmail(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Address</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_address1" name="shipper_address1" onChange={(e) => setShipperAddress(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Postal Code</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="shipper_postalcode" name="shipper_postalcode" onChange={(e) => setShipperPostalCode(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Country</label>
                      </div>
                      <div class="col-md-8">
                          <div style={{width: '100%'}}>
                            <Select
                              options={selectCountry}
                              onChange={handleChangeCountryShipper}
                            />
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>City</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="recipient_city" name="recipient_city" onChange={(e) => setShipperCity(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
              </div>

              <div class="row mb-3 align-items-center">
                <h4 style={style_pembatas}>Export Declaration</h4>
              </div>
              <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Destination Port</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="export_declaration_destination_port" name="export_declaration_destination_port" onChange={(e) => setExportDeclarationDestinationPort(e.target.value)} class="form-control" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Invoice Number</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="export_declaration_invoice_number" name="export_declaration_invoice_number" onChange={(e) => setExportDeclarationInvoiceNumber(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3 justify-content-center align-items-center">
                  <table className="table">
                      <thead>
                      <tr>
                          <th>Export Line Items &nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                      </tr>
                      </thead>
                  <tbody>
                  <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                  </tbody> 
                  </table>
              </div>
              <div class="row mb-3 justify-content-center align-items-center">
                <div class="col-sm-12">
                  <button className="button_simpan" disabled={loading} onClick={simpan}>
                    {loading ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ):
                    (<i
                      className="fa fa-save"
                      style={{ marginRight: "5px" }}
                    />)} &nbsp;
                    {loading && <span>Harap Tunggu ...</span>}
                    {!loading && <span>Simpan</span>}
                  </button>
                </div>
              </div>
          </div>
        </div>

      </div>
    </div>
            </section>

          </main>

          <Footer_admin></Footer_admin>

          
      </div>
  );
}

export default Add_order;