import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import LoadingBar from 'react-top-loading-bar';

import { edit_price } from '../../../Context/actions';
import Select from "react-select";


function Edit_country() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [priceEdit, setPriceEdit] = useState([]);
    const [selectTypePrice, setSelectTypePrice] = useState([]);
    const [selectCountry, setSelectCountry] = useState([]);
    

    const [priceDelivery, setPriceDelivery] = useState();
    const [weight, setWeight] = useState();
    const [idTypePrice, setIdTypePrice] = useState();
    const [typePrice, setTypePrice] = useState();
    const [idCountry, setIdCountry] = useState();
    const [country, setCountry] = useState();

    const [idTypePricePrice, setIdTypePricePrice] = useState([]);
    const [idCountryPrice, setIdCountryPrice] = useState([]);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const history = useHistory();

    useEffect(() => {
      fetch("https://athaexpress.net/backend/ongkir/api/showTypePrice")
      .then((res) => res.json())
      .then((data) => {
        const userDataTypePrice = data.data.map((item) => ({
          label: item.type_price, // <-- input values you are matching
          value: item.id
        }));
        setSelectTypePrice(userDataTypePrice);
      });
    },[]);
  
    useEffect(() => {
      fetch("https://athaexpress.net/backend/ongkir/api/showCountry")
      .then((res) => res.json())
      .then((data) => {
        const userData = data.data.map((item) => ({
          label: item.nama, // <-- input values you are matching
          value: item.id
        }));
       
        setSelectCountry(userData);
      });
      
    },[]);

    useEffect(() => {
      setProgress(progress + 40)
        const fetchData = async () => {
            
          const res = await fetch(
            'https://athaexpress.net/backend/ongkir/api/editPrice/'+params.get("id"),
          );
          setProgress(100)
          const json = await res.json();
          setPriceEdit(json.data);
          setPriceDelivery(json.data[0].price_delivery);
          setWeight(json.data[0].weight);
          setIdTypePrice(json.data[0].id_type_price);
          setTypePrice(json.data[0].type_price);
          setIdCountry(json.data[0].id_country);
          setCountry(json.data[0].country);
        };
        fetchData();

        // priceEdit.map(item => (
        //   console.log("Test")
        // ))
    },[]);

    const handleonSubmit = async e => {
    e.preventDefault();
    let idTipePrice,tipePrice,idNegara,negara;
    if(idTypePricePrice.length !== 0){
      idTipePrice = idTypePricePrice.idTypePricePrice.value;
      tipePrice = idTypePricePrice.idTypePricePrice.label;
    }else{
      idTipePrice = idTypePrice;
      tipePrice = typePrice;
    }

    if(idCountryPrice.length !== 0){
      idNegara = idCountryPrice.idCountryPrice.value;
      negara = idCountryPrice.idCountryPrice.label;
    }else{
      idNegara = idCountry;
      negara = country;
    }

      const promise_login = Promise.resolve(edit_price(params.get("id"),priceDelivery,weight,idTipePrice,tipePrice,idNegara,negara));
      setLoading(true)
      setProgress(progress + 40)
      promise_login.then((value) => {
          if(value.hasil == '1'){
              //sukses
              setLoading(false)
              setProgress(100)
              alert(value.message);
              history.push('/price');
              window.location.reload(true);
          }else if(value.hasil == '2'){
              //username password salah
              setLoading(false)
              setProgress(100)
              alert(value.message);
              history.push('/price');
              window.location.reload(true);
          }
      });
      
    }

    const handleChangeCountry = (idCountryPrice) => {
      setIdCountryPrice({ idCountryPrice });
    }
  
    const handleChangeTypePrice = (idTypePricePrice) => {
      setIdTypePricePrice({ idTypePricePrice });
      
    }

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Edit Price</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/price">Price</Link></li>
                    <li class="breadcrumb-item active">Edit Price</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Edit Country</h5>
                            <form onSubmit={handleonSubmit}>
                            {priceEdit.map(item => (
                                <React.Fragment>
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Price Delivery</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="priceDelivery" id="priceDelivery" defaultValue={item.price_delivery} value={priceDelivery} onChange={(e) => setPriceDelivery(e.target.value)} />
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Weight</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="weight" id="weight" defaultValue={item.weight} value={weight} onChange={(e) => setWeight(e.target.value)} />
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Type Price</label>
                                    <div class="col-sm-10">
                                      <div style={{width: '100%'}}>
                                      <Select
                                        defaultValue={{label: item.type_price, value: item.id_type_price}}
                                        options={selectTypePrice}
                                        onChange={handleChangeTypePrice}
                                      />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Country</label>
                                    <div class="col-sm-10">
                                      <div style={{width: '100%'}}>
                                      <Select
                                        defaultValue={{label: item.country, value: item.id_country}}
                                        options={selectCountry}
                                        onChange={handleChangeCountry}
                                      />
                                      </div>
                                    </div>
                                  </div>
                                  
                                </React.Fragment>
                            ))}
                            
                            <br />
                            <div class="row mb-12">
                                <div class="col-sm-12">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Edit_country;