import React from 'react';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';



function Dashboard() {

  return (
 
        
    <div>
    <Header_admin></Header_admin>
    <main id="main" class="main">

      <div class="pagetitle">
        <h1>Dashboard</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active">Home</li>
          </ol>
        </nav>
      </div>

      <section class="section">
        <div class="row">
          <div class="col-lg-6">

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">General Dashboard</h5>

              </div>
            </div>

          </div>
        </div>
      </section>

    </main>
    <Footer_admin></Footer_admin>
    </div>
        
  );
}

export default Dashboard;