import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import {Modal, Button, Form} from 'react-bootstrap';
import { add_country } from '../../../Context/actions';
import { delete_country } from '../../../Context/actions';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingSpinner from "../../loading/LoadingSpinner";

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Country() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAlert, setShowAlert] = useState(false);
  const [msgAllert, setMsgAllert] = useState();

  const history = useHistory();

  const [namaCountry, setNamaCountry] = useState();
  

  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  function showAll(message){
    setMsgAllert(message);
    setShowAlert(true);
    window.setTimeout(()=>{
      setShowAlert(false);
    },2000)
  }

  // useEffect(() => {
  //   setProgress(progress + 40)
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://athaexpress.net/backend/ongkir/api/showCountry',
  //     );
  //     const json = await res.json();
     
  //     if(json.status == "true"){
  //       setProgress(100);
  //       setCountry(json.data);
  //     }else{
  //       setProgress(100);
  //       setCountry("Data Tidak Tersedia");
  //     }
      
  //   };
  //   fetchData();
    
  // },[]);

  useEffect(() => {
    let url;
    const fetchData = async () => {
    if(query == ''){
        url = 'https://athaexpress.net/backend/ongkir/api/showCountryGetDataPagination';
        const res = await fetch(
            url,
        );
        const json = await res.json();
        
        
        if(json.status == "true"){
            setPage(page);
            setPages(json.pages);
            setRows(json.rows);
        }
        
        };

        
    }

    fetchData();
}, [page, keyword]);

useEffect(() => {
  let url;
  setProgress(progress + 40);
  if(query == ''){
      if(awal == undefined){
          url = 'https://athaexpress.net/backend/ongkir/api/showCountryPagination/0';
      }else{
          url = 'https://athaexpress.net/backend/ongkir/api/showCountryPagination/'+awal;
      }

      const fetchData = async () => {
      const res = await fetch(
          url,
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setCountry(json.data);
      }
      
      };
      fetchData();
  }
},[]);

const changePage = ({ selected }) => {
  setProgress(progress + 40);
  if(query == ''){
      setPage(selected+1);
      
      let awal;
      
      awal = (Number((selected+1)+'0') - 10)

      setAwal(awal);

      const fetchData = async () => {
      const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/showCountryPagination/'+awal,
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setCountry([]);
          setCountry(json.data);
      }else{
          setProgress(100);
          setMsg(
              "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
          );
      }
      
      };
      fetchData();
  }else{
      setPage(selected+1);
      let awal;
      
      awal = (Number((selected+1)+'0') - 10)

      setAwal(awal);
      
      const fetchData = async () => {
      const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/showCountryPaginationSearch/'+awal+'/'+query,
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setCountry([]);
          setCountry(json.data);
          
          setPages(json.pages);
          setRows(json.rows);
      }else{
          setProgress(100);
          setMsg(
              "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
          );
      }
      
      };
      fetchData();
  }
  
};

function searchData(){
  setProgress(progress + 40);
  setLoadingCari(true)
  const fetchData = async () => { 
  const res = await fetch(
      'https://athaexpress.net/backend/ongkir/api/showCountryPaginationSearch/0/'+query,
  );
  const json = await res.json();
  
  if(json.status == "true"){
      setProgress(100);
      setLoadingCari(false)
      setCountry([]);
      setPages(0);
      setRows(0);
      setPage(1);
      setCountry(json.data);
      setPages(json.pages);
      setRows(json.rows);
      
  }else{
      setProgress(100);
      setLoadingCari(false)
      setCountry(json.data);
      setPages(json.pages);
      setRows(json.rows);
      setMsg(
          "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country !"
      );
  }
  
  };
  fetchData(); 
}; 

function resetPencarian(){
  setLoadingCari(true)
  setLoadingResetPencarian(true)
  setQuery("");
  setTimeout(
      function() {
        setLoadingCari(false)
        setLoadingResetPencarian(false)
        window.location.reload(true);
      }
      ,
      2000
  );
}

  useEffect(() => {
      setTimeout(()=>{
        $("#example2").DataTable({
          destroy: true,
          "info": false,
          //dom: "rBftlip",
          // buttons: [
          //   {
    
          //   },
          // ],
          lengthMenu: [
            [10, 20, 50, 100, -1],
            [10, 20, 50, 100, "All"],
          ],
          pageLength: 10,
      });
      },1000)
  }, [])

  
  function edit_country(id){
    history.push('/edit_country?id='+id);
  }

  
  function delete_country_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setLoading(true);
      setProgress(progress + 40)
      const promise_login = Promise.resolve(delete_country(id));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setLoading(false);
                setProgress(100)
                history.push('/country');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                //username password salah
                setLoading(false);
                setProgress(100)
                history.push('/country');
                window.location.reload(true);
            }
        });
    } else {
      // Do nothing!
      // setLoading(false);
    }
  }
  
  const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true);
        setProgress(progress + 40)
        //console.log(login(username,password));
        const promise_login = Promise.resolve(add_country(namaCountry));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setLoading(false);
                setProgress(100)
                history.push('/country');
                window.location.reload(true);
                handleClose();
            }else if(value.hasil == '2'){
                setLoading(false);
                setProgress(100)
                history.push('/country');
                window.location.reload(true);
                handleClose();
            }
        });
        
  }
  return (
              
              <div>
                 <LoadingBar
                  color='#f11946'
                  progress={progress}
                  onLoaderFinished={() => setProgress(0)}
                /> 
                <Header_admin></Header_admin>
              <main id="main" class="main">
  
                <div class="pagetitle">
                  <h1 class="mb-2">Data Country</h1>
                  <nav>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                      <li class="breadcrumb-item active">Country</li>
                    </ol>
                  </nav>
                </div>
  
                <section class="section">
                  
                  <div class="row">
                    <div class="col-lg-3">
                        <button className="button_tambah_item" onClick={handleShow}>
                          <i className="fa fa-plus"
                            style={{ marginRight: "5px" }}
                          />
                          &nbsp;
                          Add Country
                        </button>
                    </div>
                    <div class="col-md-9 d-flex flex-row-reverse">
                        <form class="form-inline">
                        <input
                          type="text"
                          className="form-control"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                          placeholder="Cari sesuatu disini..."
                        />
                        &nbsp;
                        <button type="button" onClick={searchData} className="button_cari" disabled={loadingCari}>
                          {loadingCari ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-search"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loadingCari && <span>Cari</span>}
                          {!loadingCari && <span>Cari</span>}
                        </button>
                        &nbsp;
                        <button type="button" className="button_reset_pencarian" onClick={resetPencarian} disabled={loadingCari}>
                          {loadingResetPencarian ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-refresh"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loadingResetPencarian && <span>Reset Pencarian</span>}
                          {!loadingResetPencarian && <span>Reset Pencarian</span>}
                        </button>
                        </form>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-lg-12">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>KODE</th>
                          <th>NAMA</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        country != 'Data Tidak Tersedia' ?
                        country.map(item => (
                          
                            <tr>
                              <td>{item.kode}</td>
                              <td>{item.nama}</td>
                              <td>
                                <div class="w3-show-inline-block">
                                  <div class="w3-bar">
                                    <button type="button" className="button_edit" onClick={() => edit_country(item.id)} disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-edit"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Edit</span>}
                                      {!loading && <span>Edit</span>}
                                    </button>&nbsp;
                                    <button type="button" className="button_hapus" onClick={() => delete_country_onclick(item.id)} disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-times-circle"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Hapus</span>}
                                      {!loading && <span>Hapus</span>}
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                          :
                          <tr>
                            <td colSpan={5}>Data Tidak Tersedia</td>
                          </tr>
                      }
                        
                        
                      </tbody>
                    </table>
                    <p>
                        Total Rows: {rows} Page: {page} of {pages}
                    </p>
                    <p className="has-text-centered has-text-danger">{msg}</p>

                    <div id="container">
                        <ReactPaginate
                            previousLabel={"< Prev"}
                            nextLabel={"Next >"}
                            pageCount={pages}
                            onPageChange={changePage}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                    </div>
                  </div>
                </section>
  
              </main>
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Country</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div class="row"><div class="col-12"><hr /></div></div>
                <form class="row g-3 needs-validation" onSubmit={handleonSubmit} novalidate>
                <div class="row">
                  <div class="col-12">
                    <label for="yourUsername" class="form-label">Nama Negara</label>
                    <div class="input-group has-validation">
                      <input type="text" name="namaCountry" class="form-control" id="namaCountry" value={namaCountry} onChange={(e) => setNamaCountry(e.target.value)} required />
                      <div class="invalid-feedback">Masukkan Nama Negara</div>
                    </div>
                  </div>
                  </div>
                  <div class="row"><div class="col-12"><hr /></div></div>
                  <div class="row">
                  <div class="col-6">
                    <button className="button_simpan" type="submit" disabled={loading}>
                        {loading ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-save"
                          style={{ marginRight: "5px" }}
                        />)} &nbsp;
                        {loading && <span>Harap Tunggu</span>}
                        {!loading && <span>Simpan</span>}
                    </button>
                  
                  </div>
                  <div class="col-6">
                    <button className="button_close" onClick={handleClose}>
                      <i
                        className="fa fa-times-circle"
                        style={{ marginRight: "5px" }}
                      />Close
                    </button>
                  </div>
                  </div>
                  
                
                </form>
                </Modal.Body>
              </Modal>
  
              <Footer_admin></Footer_admin>
                </div>
  );
}

export default Country;