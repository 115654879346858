import React, {useState} from 'react';
import { useHistory } from 'react-router';
import { logout,isAuthenticated } from '../Context/actions';
import { Link } from 'react-router-dom';



function Header_admin() {
  const history = useHistory();

  let [nama, setNama] = useState('0');
  const [dashboard, setDashboard] = useState(false);
  const [country, setCountry] = useState(false);
  const [typePrice, setTypePrice] = useState(false);
  const [price, setPrice] = useState(false);
  const [typeItem, setTypeItem] = useState(false);
  const [orderLuwjistik, setLuwjistikOrder] = useState(false);
  const [orderAramex, setAramexOrder] = useState(false);
  const [orderDhl, setDhlOrder] = useState(false);
  const [users, setUsers] = useState(false);

  const [progress, setProgress] = useState(0);

  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }


  function teste(){
      select('body').classList.toggle('toggle-sidebar')
  }

  function keluar(){
    // setProgress(progress + 40)
    const promise1 = Promise.resolve(logout());
    promise1.then((value) => {
      //console.log(value.hasil);
      
        if(value.hasil == '1'){
            //sukses
            // setProgress(100)
            setNama('0');
           
            history.push("/admin");
        }else if(value.hasil == '2'){
            //logout gagal
            // setProgress(100)
            setNama('0');
            history.push("/admin");
        }
    });
  }

  if(nama == '0'){
    // setProgress(progress + 40)
    const promise_auth = Promise.resolve(isAuthenticated());
    promise_auth.then((value) => {
        if(value.hasil == '1'){
            //sukses
            // setProgress(100)
            setNama(value.message);
            for (let i = 0; i < value.hakakses.split("").length; i++) {
              if(value.hakakses.split("")[i] == "1"){
                setDashboard(true);
              }else if(value.hakakses.split("")[i] == "2"){
                setCountry(true);
              }else if(value.hakakses.split("")[i] == "3"){
                setTypePrice(true);
              }else if(value.hakakses.split("")[i] == "4"){
                setPrice(true);
              }else if(value.hakakses.split("")[i] == "5"){
                setTypeItem(true);
              }else if(value.hakakses.split("")[i] == "6"){
                setUsers(true);
              }else if(value.hakakses.split("")[i] == "7"){
                setLuwjistikOrder(true);
              }else if(value.hakakses.split("")[i] == "8"){
                setAramexOrder(true);
              }else if(value.hakakses.split("")[i] == "9"){
                setDhlOrder(true);
              }
            }
        }else if(value.hasil == '2'){
            //auth gagal
            // setProgress(100)
            localStorage.setItem('token', '');
            localStorage.setItem('username', '');
            localStorage.setItem('nama', '');
            setNama('0');
            history.push("/Notfound");
        }
    },[]);
  }
  

 

        return (
          <div>
            <header id="header" class="header fixed-top d-flex align-items-center">

            <div class="d-flex align-items-center justify-content-between">
              <a href="#" class="logo d-flex align-items-center">
                <img src={require('../assets_landingpage/img/fasworld.jpg')} alt="" />
                <span class="d-none d-lg-block">Fast World</span>
              </a>
              <i class="bi bi-list toggle-sidebar-btn" onClick={teste}></i>
            </div>
        
        
            <nav class="header-nav ms-auto">
              <ul class="d-flex align-items-center">
        
                <li class="nav-item d-block d-lg-none">
                  <a class="nav-link nav-icon search-bar-toggle " href="#">
                    <i class="bi bi-search"></i>
                  </a>
                </li>
        
                <li class="nav-item dropdown pe-3">
        
                  <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                    <img src={require('../assets_admin_new/img/profile-img.jpg')} alt="Profile" class="rounded-circle" />
                    <span class="d-none d-md-block dropdown-toggle ps-2">{nama}</span>
                  </a>
        
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                    <li class="dropdown-header">
                      <h6>{nama}</h6>
                      <span>Operator</span>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
        
                    <li>
                      <Link class="nav-link" to="/profile">
                        <i class="bi bi-person"></i>&nbsp;&nbsp;
                        <span>Profil</span>
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
        
                    <li>
                      <Link class="nav-link" to="/changepassword">
                        <i class="bi bi-gear"></i>&nbsp;&nbsp;
                        <span>Ganti Password</span>
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
        
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" onClick={keluar}>
                        <i class="bi bi-box-arrow-right"></i>
                        <span><b>Keluar</b></span>
                      </a>
                    </li>
        
                  </ul>
                </li>
        
              </ul>
            </nav>
        
          </header>
          <aside id="sidebar" class="sidebar">

            <ul class="sidebar-nav" id="sidebar-nav">

              <li class="nav-item" style={{display: dashboard ? 'block' : 'none' }}>
                <Link class="nav-link" to="/dashboard">
                  <i class="bi bi-grid"></i>
                  <span>Dashboard</span>
                </Link>
              </li>


              <li class="nav-heading" style={{display: country || typePrice || price || typeItem ? 'block' : 'none' }}>Pages</li>

              <li class="nav-item" style={{display: country ? 'block' : 'none' }}>
                <Link class="nav-link" to="/country">
                  <i class="bi bi-flag"></i>
                  <span>Country</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: typePrice ? 'block' : 'none' }}>
                <Link class="nav-link" to="/type_price">
                  <i class="bi bi-question-circle"></i>
                  <span>Type Price</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: price ? 'block' : 'none' }}>
                <Link class="nav-link" to="/price">
                  <i class="bi bi-currency-dollar"></i>
                  <span>Price</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: typeItem ? 'block' : 'none' }}>
                <Link class="nav-link" to="/type_item">
                  <i class="bi bi-card-list"></i>
                  <span>Type Item</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: orderLuwjistik ? 'block' : 'none' }}>Order</li>

              <li class="nav-item" style={{display: orderLuwjistik ? 'block' : 'none' }}>
                <Link class="nav-link" to="/luwjistik_order">
                  <img src={require('../assets_landingpage/img/luwjistik_icon.png')} alt="Profile" width="50px" height="20px" />
                  &nbsp;
                  <span>Order Luwjistik</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: orderAramex ? 'block' : 'none' }}>
                <Link class="nav-link" to="/aramex_order">
                  <img src={require('../assets_landingpage/img/aramex_icon.png')} alt="Profile" width="50px" height="20px" />
                  &nbsp;
                  <span>Order Aramex</span>
                </Link>
              </li>

              <li class="nav-item" style={{display: orderDhl ? 'block' : 'none' }}>
                <Link class="nav-link" to="/dhl_order">
                  <img src={require('../assets_landingpage/img/dhl_icon.png')} alt="Profile" width="50px" height="20px" />
                  &nbsp;
                  <span>Order DHL</span>
                </Link>
              </li>

              <li class="nav-heading" style={{display: users ? 'block' : 'none' }}>Setting</li>

              <li class="nav-item" style={{display: users ? 'block' : 'none' }}>
                <Link class="nav-link" to="/users">
                  <i class="bi bi-person"></i>
                  <span>Users</span>
                </Link>
              </li>
            </ul>

          </aside>
          
          
          </div>
        )
}

export default Header_admin;