import CurrencyInput from 'react-currency-input';

function TableRows({rowsData, deleteTableRows, handleChange}) {
    return(
        
        rowsData.map((data, index)=>{
            const {description, quantity, product_code, sku, category, declared_value, declared_value_currency}= data;
            return(
                <tr key={index}>
                <td>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Product Code</label>
                            </div>
                            <div class="col-md-8">
                            <input type="text" value={product_code}  onChange={(evnt)=>(handleChange(index, evnt))} name="product_code" className="form-control" />
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Description</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={description} onChange={(evnt)=>(handleChange(index, evnt))} name="description" className="form-control"/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>SKU</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={sku}  onChange={(evnt)=>(handleChange(index, evnt))} name="sku" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Quantity</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={quantity}  onChange={(evnt)=>(handleChange(index, evnt))} name="quantity" className="form-control"/>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Category</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={category}  onChange={(evnt)=>(handleChange(index, evnt))} name="category" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Price</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={declared_value} onChange={(evnt)=>(handleChange(index, evnt))} name="declared_value" id="declared_value" className="form-control" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Currency</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={declared_value_currency}  onChange={(evnt)=>(handleChange(index, evnt))} name="declared_value_currency" className="form-control" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>HAPUS</label>
                            </div>
                            <div class="col-md-8">
                                <button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </td>
                
            </tr>
            )
        })
   
    )
    
}
export default TableRows;