import React, {useEffect, useState} from 'react';
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { useLocation,Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { luwjistik_edit_order } from '../../../Context/actions';

import LoadingBar from 'react-top-loading-bar';


function Add_order() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    
    const [orderCode, setOrderCode] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();
    const [senderName, setSenderName] = useState();
    const [senderEmail, setSenderEmail] = useState();
    const [senderContactNumber, setSenderContactNumber] = useState();
    const [senderState, setSenderState] = useState();
    const [senderCity, setSenderCity] = useState();
    const [senderProvince, setSenderProvince] = useState();
    const [senderPostalCode, setSenderPostalCode] = useState();
    const [senderCountryCode, setSenderCountryCode] = useState();
    const [senderAddress, setSenderAddress] = useState();
    const [FForiginPortCode, setFFOriginPortCode] = useState();
    const [FFdestinationPortCode, setFFdestinationPortCode] = useState();
    const [CCportCode, setCCportCode] = useState();
    const [CCincoterm, setCCincoterm] = useState();
    const [consigneeName, setConsigneeName] = useState();
    const [consigneeContactNumber, setConsigneeContactNumber] = useState();
    const [consigneeAddress, setConsigneeAddress] = useState();
    const [consigneePostalCode, setConsigneePostalCode] = useState();
    const [consigneeCountryCode, setConsigneeCountryCode] = useState();
    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneeProvince, setConsigneeProvince] = useState();
    const [consigneeState, setConsigneeState] = useState();
    const [consigneeEmail, setConsigneeEmail] = useState();



    const [rowsData, setRowsData] = useState([]);

 
    const addTableRows = ()=>{
      
      const rowsInput={
          description:'',
          quantity:'',
          product_code:'',
          sku:'',
          category:'',
          declared_value:'',
          declared_value_currency:''
      } 
      setRowsData([...rowsData, rowsInput])
      
    }

    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }
 
    const handleChange = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }

    function simpan(){
      
      setSenderName('ATHA EXPRESS');
      setSenderEmail('atha@gmail.com')
      setSenderContactNumber('083833555600');
      setSenderState('KALIBATA');
      setSenderCity('JAKARTA');
      setSenderProvince('JAKARTA');
      setSenderPostalCode('12740');
      setSenderCountryCode('ID');
      setSenderAddress('KALIBATA TIMUR I NO 19');

      setLoading(true);
      setProgress(progress + 40)

      const promise1 = Promise.resolve(luwjistik_edit_order(orderCode,length,width,height,weight,senderName,senderEmail,senderContactNumber,senderState,senderCity,senderProvince,senderPostalCode,senderCountryCode,senderAddress,FForiginPortCode,FFdestinationPortCode,CCportCode,CCincoterm,consigneeName,consigneeContactNumber,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeProvince,consigneeState,consigneeEmail,rowsData));
      promise1.then((value) => {
        console.log(value.hasil);
        if(value.hasil == '1'){
            //sukses
            setLoading(false);
            setProgress(100)
            alert(value.message);
            history.push('/luwjistik_order');
            window.location.reload(true);
        }else if(value.hasil == '2'){
            //username password salah
            setLoading(false);
            setProgress(100)
            alert(value.message);
        }
      });
    }
  
    useEffect(() => {
    
      const fetchData = async () => {
          
        const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/editOrderLocal/'+params.get("trackingnumber"),
        );
        const json = await res.json();
        setOrderCode(json.data[0].trackingnumber);
        setLength(json.data[0].length);
        setWidth(json.data[0].width);
        setHeight(json.data[0].height);
        setWeight(json.data[0].weight);
        setSenderName(json.data[0].sender_name);
        setSenderEmail(json.data[0].sender_email);
        setSenderContactNumber(json.data[0].sender_contactnumber);
        setSenderState(json.data[0].sender_state);
        setSenderCity(json.data[0].sender_city);
        setSenderProvince(json.data[0].sender_province);
        setSenderPostalCode(json.data[0].sender_postalcode);
        setSenderCountryCode(json.data[0].sender_countrycode);
        setSenderAddress(json.data[0].sender_address);
        setFFOriginPortCode(json.data[0].freightforwarding_originportcode);
        setFFdestinationPortCode(json.data[0].freightforwading_destinationportcode);
        setCCportCode(json.data[0].customsclearance_portcode);
        setCCincoterm(json.data[0].customsclearance_incoterm);
        setConsigneeName(json.data[0].lastmileorder_consignee_name);
        setConsigneeContactNumber(json.data[0].lastmileorder_consignee_contactnumber);
        setConsigneeAddress(json.data[0].lastmileorder_consignee_address);
        setConsigneePostalCode(json.data[0].lastmileorder_consignee_postalcode);
        setConsigneeCountryCode(json.data[0].lastmileorder_consignee_countrycode);
        setConsigneeCity(json.data[0].lastmileorder_consignee_city);
        setConsigneeProvince(json.data[0].lastmileorder_consignee_province);
        setConsigneeState(json.data[0].lastmileorder_consignee_state);
        setConsigneeEmail(json.data[0].lastmileorder_consignee_email);
      };
      fetchData();
  
    },[]);

    useEffect(() => {
      let createPresent = [];
      const fetchData = async () => {
        setLoading(true);
        setProgress(progress + 40)
        const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/editDetailOrderLocal/'+params.get("trackingnumber"),
        );
        const json = await res.json();
        //console.log(json.data.length);
        
        for (let i = 0; i < json.data.length; i++) {
          console.log(json.data[i].description)
          createPresent=[
              ...createPresent,
              {
              description:json.data[i].description,
              quantity:json.data[i].quantity,
              product_code:json.data[i].product_code,
              sku:json.data[i].sku,
              category:json.data[i].category,
              declared_value:json.data[i].declared_value,
              declared_value_currency:json.data[i].declared_value_currency
              }
          ]

          

          // console.log(createPresent);
          setRowsData(createPresent);
          setLoading(false);
          setProgress(100)
        }
      };
      fetchData();
    },[]);

    
    
    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
     };

  return (
    <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Luwjistik Order &nbsp; <img src={require('../../../assets_landingpage/img/luwjistik_icon.png')} alt="Profile" width="100px" height="50px" /></h1>
            <br />
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Luwjistik Edit Order</li>
              </ol>
            </nav>
          </div>

          <section class="section">
          <div class="row">
    <div class="col-lg-12">

      <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Form Create Order</h4>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Order Code</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="order_code" name="order_code" defaultValue={orderCode} onChange={(e) => setOrderCode(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Length</label>
                    </div>
                    <div class="col-md-8">
                      <input type="number" id="length" name="length" defaultValue={length} onChange={(e) => setLength(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>Height</label>
                      </div>
                      <div class="col-md-8">
                        <input type="number" id="height" name="height" defaultValue={height} onChange={(e) => setHeight(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Width</label>
                    </div>
                    <div class="col-md-8">
                      <input type="number" id="width" name="width" defaultValue={width} onChange={(e) => setWidth(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Weight</label>
                      </div>
                      <div class="col-md-8">
                        <input type="number" id="weight" name="weight" defaultValue={weight} onChange={(e) => setWeight(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Freight Forwarding & Custom Clearance</h4>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Origin Port Code</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="freightforwarding_origin_port_code" name="freightforwarding_origin_port_code" defaultValue={FForiginPortCode} onChange={(e) => setFFOriginPortCode(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>Port Code (Custom Clearance)</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="customs_clearance_port_code" name="customs_clearance_port_code" defaultValue={CCportCode} onChange={(e) => setCCportCode(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Destination Port Code</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="freightforwarding_destination_port_code" name="freightforwarding_destination_port_code" defaultValue={FFdestinationPortCode} onChange={(e) => setFFdestinationPortCode(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Incoterm (Custom Clearance)</label>
                      </div>
                      <div class="col-md-8">
                      <input type="text" id="customs_clearance_incoterm" name="customs_clearance_incoterm" defaultValue={CCincoterm} onChange={(e) => setCCincoterm(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Consignee</h4>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Name (Consignee)</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="consignee_name" name="consignee_name" defaultValue={consigneeName} onChange={(e) => setConsigneeName(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>Country Code (Consignee)</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_country_code" name="consignee_country_code" defaultValue={consigneeCountryCode} onChange={(e) => setConsigneeCountryCode(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Contact Number (Consignee)</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="consignee_contact_number" name="consignee_contact_number" defaultValue={consigneeContactNumber} onChange={(e) => setConsigneeContactNumber(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>City (Consignee)</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_city" name="consignee_city" defaultValue={consigneeCity} onChange={(e) => setConsigneeCity(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Address (Consignee)</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="consignee_address" name="consignee_address" defaultValue={consigneeAddress} onChange={(e) => setConsigneeAddress(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>Province (Consignee)</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_province" name="consignee_province" defaultValue={consigneeProvince} onChange={(e) => setConsigneeProvince(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Postal Code (Consignee)</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="consignee_postal_code" name="consignee_postal_code" defaultValue={consigneePostalCode} onChange={(e) => setConsigneePostalCode(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>State (Consignee)</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="consignee_state" name="consignee_state" defaultValue={consigneeState} onChange={(e) => setConsigneeState(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Email (Consignee)</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="consignee_email" name="consignee_email" defaultValue={consigneeEmail} onChange={(e) => setConsigneeEmail(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
            </div>
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Item</h4>
            </div>
            <div class="row mb-3 justify-content-center align-items-center">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Tambah Item &nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                    </tr>
                    </thead>
                <tbody>
                <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                </tbody> 
                </table>
            </div>
            <div class="row mb-3 justify-content-center align-items-center">
              <div class="col-sm-12">
                <button className="button_simpan" disabled={loading} onClick={simpan}>
                  {loading ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  ):
                  (<i
                    className="fa fa-save"
                    style={{ marginRight: "5px" }}
                  />)} &nbsp;
                  {loading && <span>Harap Tunggu ...</span>}
                  {!loading && <span>Simpan</span>}
                </button>
              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

        
    </div>
  );
}

export default Add_order;