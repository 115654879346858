import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { useLocation, Link } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { edit_type_item } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Country() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  const [typeItemEdit, setTypeItemEdit] = useState([]);

  const [namaTypeItem, setNamaTypeItem] = useState();

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingCariItem, setLoadingCariItem] = useState(false);
  const [progress, setProgress] = useState(0);

  const [idPrice, setIdPrice] = useState();

  const [price, setPrice] = useState([]);
  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");


  useEffect(() => {
      let url;
      const fetchData = async () => {
      if(query == ''){
          url = 'https://athaexpress.net/backend/ongkir/api/showPriceGetDataPagination';
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          
          if(json.status == "true"){
              setPage(page);
              setPages(json.pages);
              setRows(json.rows);
          }
          
          };

          
      }

      fetchData();
  }, [page, keyword]);

  useEffect(() => {
      let url;
      setProgress(progress + 40);
      if(query == ''){
          if(awal == undefined){
              url = 'https://athaexpress.net/backend/ongkir/api/showPricePagination/0';
          }else{
              url = 'https://athaexpress.net/backend/ongkir/api/showPricePagination/'+awal;
          }

          const fetchData = async () => {
          const res = await fetch(
              url,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setPrice(json.data);
          }
          
          };
          fetchData();
      }
    },[]);

  const changePage = ({ selected }) => {
      setProgress(progress + 40);
      if(query == ''){
          setPage(selected+1);
          
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);

          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showPricePagination/'+awal,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setPrice([]);
              setPrice(json.data);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }else{
          setPage(selected+1);
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          
          const fetchData = async () => {
          const res = await fetch(
              'https://athaexpress.net/backend/ongkir/api/showPricePaginationSearch/'+awal+'/'+query,
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setPrice([]);
              setPrice(json.data);
              
              setPages(json.pages);
              setRows(json.rows);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }
      
  };

  function searchData(){
      setProgress(progress + 40);
      setLoadingCari(true)
      const fetchData = async () => { 
      const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/showPricePaginationSearch/0/'+query,
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setLoadingCari(false)
          setPrice([]);
          setPages(0);
          setRows(0);
          setPage(1);
          setPrice(json.data);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
          setProgress(100);
          setLoadingCari(false)
          setPrice(json.data);
          setPages(json.pages);
          setRows(json.rows);
          setMsg(
              "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country !"
          );
      }
      
      };
      fetchData(); 
  };

  // useEffect(() => {
    
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://athaexpress.net/backend/ongkir/api/showPrice',
  //     );
  //     const json = await res.json();
  //     if(json.status == "true"){
  //       setPrice(json.data);
  //     }else{
  //       setPrice("Data Tidak Tersedia");
  //     }
      
      
  //   };
  //   fetchData();
    
  // },[]);

  useEffect(() => {
    setProgress(progress + 40)
    setLoading(true)
    const fetchData = async () => {
        
      const res = await fetch(
        'https://athaexpress.net/backend/ongkir/api/editTypeItem/'+params.get("id"),
      );
      const json = await res.json();
      setProgress(100)
      setLoading(false)
      setTypeItemEdit(json.data);
      setIdPrice(json.data[0].id_price);
      setNamaTypeItem(json.data[0].name);
    };
    fetchData();

  },[]);
  

  function use_price(id,country,type_price,weight,price_delivery){
        setIdPrice(id);
        document.getElementById("country").value = country;
        document.getElementById("type_price").value = type_price;
        document.getElementById("weight").value = weight;
        document.getElementById("price_delivery").value = price_delivery;
        handleClose();
  }
  
  const handleonSubmit = async e => {
    e.preventDefault();
        let idP,namaTipeItem;
        
        idP = idPrice;
        namaTipeItem = namaTypeItem;
        setProgress(progress + 40)
        setLoading(true)
        const promise_login = Promise.resolve(edit_type_item(params.get("id"),idP,namaTipeItem));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                alert(value.message);
                setLoading(false)
                setProgress(100)
                history.push('/type_item');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                alert(value.message);
                setLoading(false)
                setProgress(100)
                history.push('/type_item');
                window.location.reload(true);
            }
        });
  }

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Data Type Item</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/type_item">Type Item</Link></li>
                    <li class="breadcrumb-item active">Edit Type Item</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
              <div class="row">
        <div class="col-lg-12">

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Form Type Item</h5>

              <form onSubmit={handleonSubmit}>
              {typeItemEdit.map(item => (
                <React.Fragment>
                <div class="row mb-3">
                  <label for="inputText" class="col-sm-2 col-form-label">Nama</label>
                  <div class="col-sm-10">
                    <input type="text" id="namaTypeItem" name="namaTypeItem" defaultValue={item.name} onChange={(e) => setNamaTypeItem(e.target.value)} class="form-control" />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmail" class="col-sm-2 col-form-label">Price</label>
                  <div class="col-lg-10">
                        <button type="button" className="button_cari_modal" style={{width: '100%'}} onClick={handleShow}>
                          {loadingCariItem ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-save"
                            style={{ marginRight: "5px" }}
                          />)} &nbsp;
                          {loadingCariItem && <span>Harap Tunggu ...</span>}
                          {!loadingCariItem && <span>Cari Price</span>}
                        </button>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Country</label>
                  <div class="col-sm-10">
                    <input type="text" id="country" name="country" defaultValue={item.country} class="form-control" disabled />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Type Price</label>
                  <div class="col-sm-10">
                    <input type="text" id="type_price" name="type_price" defaultValue={item.type_price} class="form-control" disabled />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Weight</label>
                  <div class="col-sm-10">
                    <input type="text" id="weight" name="weight" defaultValue={item.weight} class="form-control" disabled />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Price Delivery</label>
                  <div class="col-sm-10">
                    <input type="text" id="price_delivery" name="price_delivery" defaultValue={item.price_delivery} class="form-control" disabled />
                  </div>
                </div>

                <div class="row mb-3">
                  <label class="col-sm-2 col-form-label"></label>
                  <div class="col-sm-10">
                    <button type="submit" className="button_simpan" disabled={loading}>
                      {loading ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ):
                      (<i
                        className="fa fa-save"
                        style={{ marginRight: "5px" }}
                      />)} &nbsp;
                      {loading && <span>Harap Tunggu ...</span>}
                      {!loading && <span>Simpan</span>}
                    </button>
                  </div>
                </div>
                </React.Fragment>
                ))}
              </form>

            </div>
          </div>

        </div>
      </div>
              </section>

            </main>
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Type Price</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div class="row">
              <div class="col-md-12 d-flex flex-row-reverse">
                  <form class="form-inline">
                  <input
                    type="text"
                    className="form-control"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Cari sesuatu disini..."
                  />
                  &nbsp;
                  <button type="button" onClick={searchData} className="button_cari" disabled={loadingCari}>
                    {loadingCari ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ):
                    (<i
                      className="fa fa-search"
                      style={{ marginRight: "5px" }}
                    />)}
                    {loadingCari && <span>Cari</span>}
                    {!loadingCari && <span>Cari</span>}
                  </button>
                  </form>
              </div>
            </div>
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                    <th>Country</th>
                    <th>Type Price</th>
                    <th>Weight</th>
                    <th>Price Delivery</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    price != 'Data Tidak Tersedia' ?
                    price.map(item => (
                    
                        <tr>
                        <td>{item.country}</td>
                        <td>{item.type_price}</td>
                        <td>{item.weight}</td>
                        <td>{item.price_delivery}</td>
                        <td>
                            <button type="button" class="btn btn-info" onClick={() => use_price(item.id,item.country,item.type_price,item.weight,item.price_delivery)}>Use</button>
                        </td>
                        </tr>
                    
                    ))
                    :
                    <tr>
                        <td colSpan={5}>Data Tidak Tersedia</td>
                    </tr>
                }
                </tbody>
            </table>
            <p>
                Total Rows: {rows} Page: {page} of {pages}
            </p>
            <p className="has-text-centered has-text-danger">{msg}</p>

            <div id="container">
                <ReactPaginate
                    previousLabel={"< Prev"}
                    nextLabel={"Next >"}
                    pageCount={pages}
                    onPageChange={changePage}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
            </Modal.Body>
          </Modal>

            <Footer_admin></Footer_admin>

            
        </div>
        
  );
}

export default Country;