import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {Modal, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "bulma/css/bulma.css";

import LoadingSpinner from "../loading/LoadingSpinner";


import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"


function Country() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [awal, setAwal] = useState();
    const [pages, setPages] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let url;
        const fetchData = async () => {
        if(query == ''){
            url = 'https://athaexpress.net/backend/ongkir/api/showPriceGetDataPagination';
            const res = await fetch(
                url,
            );
            const json = await res.json();
            
            
            if(json.status == "true"){
                setPage(page);
                setPages(json.pages);
                setRows(json.rows);
            }
            
            };

            
        }

        fetchData();
    }, [page, keyword]);

    useEffect(() => {
        let url;

        if(query == ''){
            if(awal == undefined){
                url = 'https://athaexpress.net/backend/ongkir/api/showPricePagination/0';
            }else{
                url = 'https://athaexpress.net/backend/ongkir/api/showPricePagination/'+awal;
            }

            const fetchData = async () => {
            const res = await fetch(
                url,
            );
            const json = await res.json();
            
            if(json.status == "true"){
                setUsers(json.data);
            }
            
            };
            fetchData();
        }
      },[]);

    const changePage = ({ selected }) => {
        if(query == ''){
            setPage(selected+1);

            let awal;
            
            awal = (Number((selected+1)+'0') - 10)

            setAwal(awal);

            const fetchData = async () => {
            const res = await fetch(
                'https://athaexpress.net/backend/ongkir/api/showPricePagination/'+awal,
            );
            const json = await res.json();
            
            if(json.status == "true"){
                setUsers([]);
                setUsers(json.data);
            }else{
                setMsg(
                    "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
                );
            }
            
            };
            fetchData();
        }else{
            setPage(selected+1);
            setPages(0);
            setRows(0);
            let awal;
            
            awal = (Number((selected+1)+'0') - 10)

            setAwal(awal);
            
            const fetchData = async () => {
            const res = await fetch(
                'https://athaexpress.net/backend/ongkir/api/showPricePaginationSearch/'+awal+'/'+query,
            );
            const json = await res.json();
            
            if(json.status == "true"){
                setUsers([]);
                setUsers(json.data);
                
                setPages(json.pages);
                setRows(json.rows);
            }else{
                setMsg(
                    "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
                );
            }
            
            };
            fetchData();
        }
        
    };

    function searchData(){
        setLoading(true);
        const fetchData = async () => { 
        const res = await fetch(
            'https://athaexpress.net/backend/ongkir/api/showPricePaginationSearch/0/'+query,
        );
        const json = await res.json();
        
        if(json.status == "true"){
            setLoading(false);
            setUsers([]);
            setPages(0);
            setRows(0);
            setPage(1);
            setUsers(json.data);
            setPages(json.pages);
            setRows(json.rows);
            
        }else{
            setLoading(false);
            setUsers(json.data);
            setPages(json.pages);
            setRows(json.rows);
            setMsg(
                "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Country !"
            );
        }
        
        };
        fetchData(); 
    }; 

    function resetPencarian(){
        setQuery("");
        window.location.reload(true);
    }

    function edit_country(id){
    }

    function delete_country_onclick(){

    }
  return (
    <div className="container mt-5">
      <div className="columns">
        <div className="column is-centered">
        <div class="row mb-3" style={{alignSelf: 'flex-end'}}>
            <div class="col-md-7">
            <Button className="nextButton">
                          Add Country
                        </Button>
            </div>
            <div class="col-md-5">
                <form class="form-inline">
                <input
                  type="text"
                  className="form-control"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Cari sesuatu disini..."
                />
                &nbsp;
                <button type="button" onClick={searchData} className="btn btn-primary">
                  Cari
                </button>
                &nbsp;
                <button type="button" className="btn btn-danger" onClick={resetPencarian}>
                  Reset Pencarian
                </button>
                </form>
            </div>
        </div>
          <div>
                {loading ? <LoadingSpinner /> : 
                <div>
                    <table className="table table-hover table-bordered">
                        <thead>
                        <tr>
                            <th>Price Delivery</th>
                            <th>Weight</th>
                            <th>Type Price</th>
                            <th>Country</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                        users != 'Data Tidak Tersedia' ?
                        users.map((user) => (
                            <tr>
                            <td>{user.id}</td>
                            <td>{user.country}</td>
                            <td>
                                <div class="w3-show-inline-block">
                                  <div class="w3-bar">
                                    <button type="button" class="btn btn-info" onClick={() => edit_country(user.id)}>Edit</button>&nbsp;
                                    <button type="button" class="btn btn-danger" onClick={() => delete_country_onclick(user.id)}>Delete</button>
                                  </div>
                                </div>
                            </td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td colSpan={5}>Data Tidak Tersedia</td>
                        </tr>
                        }
                        </tbody>
                    </table>
                    <p>
                        Total Rows: {rows} Page: {page} of {pages}
                    </p>
                    <p className="has-text-centered has-text-danger">{msg}</p>

                    <div id="container">
                        <ReactPaginate
                            previousLabel={"< Prev"}
                            nextLabel={"Next >"}
                            pageCount={pages}
                            onPageChange={changePage}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
                
                
                }
            </div>
        </div>
      </div>
    </div>
        
  );
}

export default Country;