import React from "react";
import "./spinner.css";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function LoadingSpinner() {
  return (
    <div class="loader-container">
           
    <main id="main" class="main">
        <div class="spinner"></div><br />
    </main>
    <div id="proses_ambil_data">Proses Simpan Data</div>
    </div>
  );
}