import React, {useEffect, useState} from 'react';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { useLocation,Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { aramex_edit_order } from '../../../Context/actions';

import LoadingBar from 'react-top-loading-bar';


function Edit_order() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [aramexEdit, setAramexEdit] = useState([]);
    const [foreignHawb, setForeignHawb] = useState();
    const [reference, setReference] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();
    const [numberOfPieces, setNumberOfPieces] = useState();
    const [shipperName, setShipperName] = useState();
    const [shipperCompany, setShipperCompany] = useState();
    const [shipperPhone, setShipperPhone] = useState();
    const [shipperEmail, setShipperEmail] = useState();
    const [shipperAddress, setShipperAddress] = useState();
    const [shipperCity, setShipperCity] = useState();
    const [shipperPostalCode, setShipperPostalCode] = useState();
    const [shipperCountryCode, setShipperCountryCode] = useState();
    const [consigneeName, setConsigneeName] = useState();
    const [consigneeCompany, setConsigneeCompany] = useState();
    const [consigneePhone, setConsigneePhone] = useState();
    const [consigneeEmail, setConsigneeEmail] = useState();
    const [consigneeAddress, setConsigneeAddress] = useState();
    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneePostalCode, setConsigneePostalCode] = useState();
    const [consigneeCountryCode, setConsigneeCountryCode] = useState();

    const [idProductType, setIdProductType] = useState([]);
    const [productType, setProductType] = useState([]);
    const [idProductGroup, setIdProductGroup] = useState([]);
    const [productGroup, setProductGroup] = useState([]);
    const [idPaymentType, setIdPaymentType] = useState([]);
    const [paymentType, setPaymentType] = useState([]);

    const [listProductGroup, setListProductGroup] = useState([]);
    const [listProductType, setListProductType] = useState([]);
    const [listPaymentType, setListPaymentType] = useState([]);

    const [rowsData, setRowsData] = useState([]);

    useEffect(() => {
      let productType = [{
          label: 'Priority Document Express (PDX)',
          value: 'PDX'
        },{
          label: 'Priority Parcel Express (PPX)',
          value: 'PPX'
        },{
          label: 'Priority Letter Express (PLX)',
          value: 'PLX'
        },{
          label: 'Deferred Document Express (DDX)',
          value: 'DDX'
        },{
          label: 'Deferred Parcel Express (DPX)',
          value: 'DPX'
        },{
          label: 'Ground Document Express (GDX)',
          value: 'GDX'
        },{
          label: 'Ground Parcel Express (GPX)',
          value: 'GPX'
        },{
          label: 'Economy Parcel Express (EPX)',
          value: 'EPX'
        }];

      let productGroup = [{
          label: 'Express (EXP)',
          value: 'EXP'
        },{
          label: 'Domestic (DOM)',
          value: 'DOM'
        }];

      let paymentType = [{
          label: 'Prepaid (P)',
          value: 'P'
        },{
          label: 'Collect (C)',
          value: 'C'
        },{
          label: 'Third Party (3)',
          value: '3'
        }];

        setListProductType(productType)
        setListProductGroup(productGroup)
        setListPaymentType(paymentType)
    },[]);
 
    const addTableRows = ()=>{
      
      const rowsInput={
          package_type:'',
          quantity:'',
          weight:'',
          comment:''
      } 
      setRowsData([...rowsData, rowsInput])
      
    }

    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }
 
    const handleChange = (index, evnt)=>{
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }

    function simpan(){
      //setLoading(true);
      setShipperName('ATHA EXPRESS');
      setShipperEmail('atha@gmail.com')
      setShipperPhone('083833555600');
      setShipperCity('JAKARTA');
      setShipperPostalCode('12740');
      setShipperCountryCode('ID');
      setShipperAddress('KALIBATA TIMUR I NO 19');

      let singkatanProductGroup,kepanjanganProductGroup,singkatanProductType,kepanjanganProductType,singkatanPaymentType,kepanjanganPaymentType;

      if(idProductGroup.length == undefined){
        singkatanProductGroup = idProductGroup.idProductGroup.value;
        kepanjanganProductGroup = idProductGroup.idProductGroup.label;
      }else{
        singkatanProductGroup = idProductGroup;
        kepanjanganProductGroup = productGroup;
      }
      if(idProductType.length == undefined){
        singkatanProductType = idProductType.idProductType.value;
        kepanjanganProductType = idProductType.idProductType.label;
      }else{
        singkatanProductType = idProductType;
        kepanjanganProductType = productType;
      }
      if(idPaymentType.length == undefined){
        singkatanPaymentType = idPaymentType.idPaymentType.value;
        kepanjanganPaymentType = idPaymentType.idPaymentType.label;
      }else{
        singkatanPaymentType = idPaymentType;
        kepanjanganPaymentType = paymentType;
      }

      setLoading(true);
      setProgress(progress + 40)

      const promise1 = Promise.resolve(aramex_edit_order(params.get("foreignhawb"),foreignHawb,reference,length,width,height,weight,singkatanProductGroup,kepanjanganProductGroup,singkatanProductType,kepanjanganProductType,singkatanPaymentType,kepanjanganPaymentType,numberOfPieces,shipperName,shipperCompany,shipperEmail,shipperPhone,shipperCity,shipperPostalCode,shipperCountryCode,shipperAddress,consigneeName,consigneeCompany,consigneePhone,consigneeAddress,consigneePostalCode,consigneeCountryCode,consigneeCity,consigneeEmail,rowsData));
      promise1.then((value) => {
        console.log(value.hasil);
        if(value.hasil == '1'){
            //sukses
            setLoading(false);
            setProgress(100)
            alert(value.message);
            history.push('/aramex_order');
            window.location.reload(true);
        }else if(value.hasil == '2'){
            //username password salah
            setLoading(false);
            setProgress(100);
            alert(value.message);
        }
      });
    }
  
    useEffect(() => {
    
      const fetchData = async () => {
          
        const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/editOrderLocalAramex/'+params.get("foreignhawb"),
        );
        const json = await res.json();
        setAramexEdit(json.data);
        if(json.data[0].foreignhawb == 'undefined' || json.data[0].foreignhawb == '0'){
          setForeignHawb('');
        }else{
          setForeignHawb(json.data[0].foreignhawb);
        }
        if(json.data[0].reference == 'undefined' || json.data[0].reference == '0'){
          setReference('');
        }else{
          setReference(json.data[0].reference);
        }
        if(json.data[0].length == 'undefined' || json.data[0].length == '0'){
          setLength('');
        }else{
          setLength(json.data[0].length);
        }
        if(json.data[0].width == 'undefined' || json.data[0].width == '0'){
          setWidth('');
        }else{
          setWidth(json.data[0].width);
        }
        if(json.data[0].height == 'undefined' || json.data[0].height == '0'){
          setHeight('');
        }else{
          setHeight(json.data[0].height);
        }
        if(json.data[0].actually_weight == 'undefined' || json.data[0].actually_weight == '0'){
          setWeight('');
        }else{
          setWeight(json.data[0].actually_weight);
        }
        if(json.data[0].number_of_pieces == 'undefined' || json.data[0].number_of_pieces == '0'){
          setNumberOfPieces('');
        }else{
          setNumberOfPieces(json.data[0].number_of_pieces);
        }
        setIdProductGroup(json.data[0].product_group);
        setProductGroup(json.data[0].product_group_fullname);
        setIdProductType(json.data[0].product_type);
        setProductType(json.data[0].product_type_fullname);
        setIdPaymentType(json.data[0].payment_type);
        setPaymentType(json.data[0].payment_type_fullname);
        if(json.data[0].shipper_name == 'undefined' || json.data[0].shipper_name == '0'){
          setShipperName('');
        }else{
          setShipperName(json.data[0].shipper_name);
        }
        if(json.data[0].shipper_company == 'undefined' || json.data[0].shipper_company == '0'){
          setShipperCompany('');
        }else{
          setShipperCompany(json.data[0].shipper_company);
        }
        if(json.data[0].shipper_phone == 'undefined' || json.data[0].shipper_phone == '0'){
          setShipperPhone('');
        }else{
          setShipperPhone(json.data[0].shipper_phone);
        }
        if(json.data[0].shipper_email == 'undefined' || json.data[0].shipper_email == '0'){
          setShipperEmail('');
        }else{
          setShipperEmail(json.data[0].shipper_email);
        }
        if(json.data[0].shipper_address == 'undefined' || json.data[0].shipper_address == '0'){
          setShipperAddress('');
        }else{
          setShipperAddress(json.data[0].shipper_address);
        }
        if(json.data[0].shipper_city == 'undefined' || json.data[0].shipper_city == '0'){
          setShipperCity('');
        }else{
          setShipperCity(json.data[0].shipper_city);
        }
        if(json.data[0].shipper_postalcode == 'undefined' || json.data[0].shipper_postalcode == '0'){
          setShipperPostalCode('');
        }else{
          setShipperPostalCode(json.data[0].shipper_postalcode);
        }
        if(json.data[0].shipper_countrycode == 'undefined' || json.data[0].shipper_countrycode == '0'){
          setShipperCountryCode('');
        }else{
          setShipperCountryCode(json.data[0].shipper_countrycode);
        }
        if(json.data[0].consignee_name == 'undefined' || json.data[0].consignee_name == '0'){
          setConsigneeName('');
        }else{
          setConsigneeName(json.data[0].consignee_name);
        }
        if(json.data[0].consignee_company == 'undefined' || json.data[0].consignee_company == '0'){
          setConsigneeCompany('');
        }else{
          setConsigneeCompany(json.data[0].consignee_company);
        }
        if(json.data[0].consignee_phone == 'undefined' || json.data[0].consignee_phone == '0'){
          setConsigneePhone('');
        }else{
          setConsigneePhone(json.data[0].consignee_phone);
        }
        if(json.data[0].consignee_email == 'undefined' || json.data[0].consignee_email == '0'){
          setConsigneeEmail('');
        }else{
          setConsigneeEmail(json.data[0].consignee_email);
        }
        if(json.data[0].consignee_address == 'undefined' || json.data[0].consignee_address == '0'){
          setConsigneeAddress('');
        }else{
          setConsigneeAddress(json.data[0].consignee_address);
        }
        if(json.data[0].consignee_city == 'undefined' || json.data[0].consignee_city == '0'){
          setConsigneeCity('');
        }else{
          setConsigneeCity(json.data[0].consignee_city);
        }
        if(json.data[0].consignee_postalcode == 'undefined' || json.data[0].consignee_postalcode == '0'){
          setConsigneePostalCode('');
        }else{
          setConsigneePostalCode(json.data[0].consignee_postalcode);
        }
        if(json.data[0].consignee_countrycode == 'undefined' || json.data[0].consignee_countrycode == '0'){
          setConsigneeCountryCode('');
        }else{
          setConsigneeCountryCode(json.data[0].consignee_countrycode);
        }
      };
      fetchData();
  
    },[]);

    useEffect(() => {
      let createPresent = [];
      const fetchData = async () => {
        setLoading(true);
        setProgress(progress + 40)
        const res = await fetch(
          'https://athaexpress.net/backend/ongkir/api/editDetailOrderLocalAramex/'+params.get("foreignhawb"),
        );
        const json = await res.json();
        //console.log(json.data.length);
        
        for (let i = 0; i < json.data.length; i++) {
          //console.log(json.data[i].product_type)
          createPresent=[
              ...createPresent,
              {
                package_type:json.data[i].package_type,
                quantity:json.data[i].quantity,
                weight:json.data[i].weight,
                comment:json.data[i].comment
              }
          ]

          setRowsData(createPresent);
          setLoading(false);
          setProgress(100)
        }
      };
      fetchData();
    },[]);

    
    
    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
    };

    const handleChangeProductGroup = (idProductGroup) => {
      setIdProductGroup({ idProductGroup });
    }
  
    const handleChangeProductType = (idProductType) => {
      setIdProductType({ idProductType });
    }
     
    const handleChangePaymentType = (idPaymentType) => {
      setIdPaymentType({ idPaymentType });
    }

  return (
    <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Aramex Order&nbsp; <img src={require('../../../assets_landingpage/img/aramex_icon.png')} alt="Profile" width="80px" height="40px" /></h1>
            <br />
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Aramex Edit Order</li>
              </ol>
            </nav>
          </div>

          <section class="section">
              <div class="row">
        <div class="col-lg-12">

          <div class="card">
            <div class="card-body">
                <div class="row mb-3 align-items-center">
                  <h4 style={style_pembatas}>Form Create Order</h4>
                </div>
                {aramexEdit.map(item => (
                    <React.Fragment>
                      <div class="row">
                          <div class="col-md-6 mb-3">
                            <div class="row">
                              <div class="col-md-4">
                                <label>Foreign HAWB</label>
                              </div>
                              <div class="col-md-8">
                                <input type="text" id="foreign_hawb" name="foreign_hawb" defaultValue={item.foreignhawb} value={foreignHawb} onChange={(e) => setForeignHawb(e.target.value)} class="form-control" />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Reference</label>
                                </div>
                                <div class="col-md-8">
                                  <input type="text" id="reference" name="reference" defaultValue={item.reference} value={reference} onChange={(e) => setReference(e.target.value)} class="form-control" />
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-3">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Length</label>
                                </div>
                                <div class="col-md-8">
                                  <input type="number" id="length" name="length" defaultValue={item.length} value={length} onChange={(e) => setLength(e.target.value)} class="form-control" />
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Width</label>
                                </div>
                                <div class="col-md-8">
                                  <input type="number" id="width" name="width" defaultValue={item.width} value={width} onChange={(e) => setWidth(e.target.value)} class="form-control" />
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-3">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Height</label>
                                </div>
                                <div class="col-md-8">
                                  <input type="number" id="height" name="height" defaultValue={item.height} value={height} onChange={(e) => setHeight(e.target.value)} class="form-control" />
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Weight</label>
                                </div>
                                <div class="col-md-8">
                                  <input type="number" id="weight" name="weight" defaultValue={item.actually_weight} value={weight} onChange={(e) => setWeight(e.target.value)} class="form-control" />
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6 mb-3">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Product Group</label>
                                </div>
                                <div class="col-md-8">
                                  <div style={{width: '100%'}}>
                                  <Select
                                      defaultValue={{label: item.product_group_fullname, value: item.product_group}}
                                      options={listProductGroup}
                                      onChange={handleChangeProductGroup}
                                  />
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Product Type</label>
                                </div>
                                <div class="col-md-8">
                                  <div style={{width: '100%'}}>
                                  <Select
                                      defaultValue={{label: item.product_type_fullname, value: item.product_type}}
                                      options={listProductType}
                                      onChange={handleChangeProductType}
                                  />
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Payment Type</label>
                                </div>
                                <div class="col-md-8">
                                  <div style={{width: '100%'}}>
                                  <Select
                                      defaultValue={{label: item.payment_type_fullname, value: item.payment_type}}
                                      options={listPaymentType}
                                      onChange={handleChangePaymentType}
                                  />
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-4">
                                <label>Quantity Item</label>
                                </div>
                                <div class="col-md-8">
                                  <input type="number" id="number_of_pieces" name="number_of_pieces" defaultValue={item.number_of_pieces} value={numberOfPieces} onChange={(e) => setNumberOfPieces(e.target.value)} class="form-control" />
                                </div>
                              </div>
                          </div>
                      </div>
                    </React.Fragment>
                ))}
                
                <div class="row mb-3 align-items-center">
                  <h4 style={style_pembatas}>Shipper</h4>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Name</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="shipper_name" name="shipper_name" defaultValue={shipperName} onChange={(e) => setShipperName(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                          <label>Company</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="shipper_company" name="shipper_company" defaultValue={shipperCompany} onChange={(e) => setShipperCompany(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Phone</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="shipper_phone" name="shipper_phone" defaultValue={shipperPhone} onChange={(e) => setShipperPhone(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <label>Email</label>
                          </div>
                          <div class="col-md-8">
                          <input type="text" id="shipper_email" name="shipper_email" defaultValue={shipperEmail} onChange={(e) => setShipperEmail(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Address</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="shipper_address" name="shipper_address" defaultValue={shipperAddress} onChange={(e) => setShipperAddress(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <label>City</label>
                          </div>
                          <div class="col-md-8">
                          <input type="text" id="shipper_city" name="shipper_city" defaultValue={shipperCity} onChange={(e) => setShipperCity(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Postal Code</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="shipper_postalcode" name="shipper_postalcode" defaultValue={shipperPostalCode} onChange={(e) => setShipperPostalCode(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <label>Country Code</label>
                          </div>
                          <div class="col-md-8">
                          <input type="text" id="shipper_countrycode" name="shipper_countrycode" defaultValue={shipperCountryCode} onChange={(e) => setShipperCountryCode(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                
                <div class="row mb-3 align-items-center">
                  <h4 style={style_pembatas}>Consignee</h4>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Name</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="consignee_name" name="consignee_name" defaultValue={consigneeName} onChange={(e) => setConsigneeName(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                          <label>Company</label>
                          </div>
                          <div class="col-md-8">
                            <input type="text" id="consignee_company" name="consignee_company" defaultValue={consigneeCompany} onChange={(e) => setConsigneeCompany(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Phone</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="consignee_phone" name="consignee_phone" defaultValue={consigneePhone} onChange={(e) => setConsigneePhone(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <label>Email</label>
                          </div>
                          <div class="col-md-8">
                          <input type="text" id="consignee_email" name="consignee_email" defaultValue={consigneeEmail} onChange={(e) => setConsigneeEmail(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Address</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="consignee_address" name="consignee_address" defaultValue={consigneeAddress} onChange={(e) => setConsigneeAddress(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <label>City</label>
                          </div>
                          <div class="col-md-8">
                          <input type="text" id="consignee_city" name="consignee_city" defaultValue={consigneeCity} onChange={(e) => setConsigneeCity(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                          <label>Postal Code</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="consignee_postalcode" name="consignee_postalcode" defaultValue={consigneePostalCode} onChange={(e) => setConsigneePostalCode(e.target.value)} class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4">
                            <label>Country Code</label>
                          </div>
                          <div class="col-md-8">
                          <input type="text" id="consignee_countrycode" name="consignee_countrycode" defaultValue={consigneeCountryCode} onChange={(e) => setConsigneeCountryCode(e.target.value)} class="form-control" />
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3 align-items-center">
                  <h4 style={style_pembatas}>Item</h4>
                </div>
                <div class="row mb-3 justify-content-center align-items-center">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Tambah Item &nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-outline-success" onClick={addTableRows} >+</button></th>
                        </tr>
                        </thead>
                    <tbody>
                    <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                    </tbody> 
                    </table>
                </div>
                <div class="row mb-3 justify-content-center align-items-center">
                  <div class="col-sm-12">
                    <button className="button_simpan" disabled={loading} onClick={simpan}>
                      {loading ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ):
                      (<i
                        className="fa fa-save"
                        style={{ marginRight: "5px" }}
                      />)} &nbsp;
                      {loading && <span>Harap Tunggu ...</span>}
                      {!loading && <span>Simpan</span>}
                    </button>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div>
              </section>

        </main>
        <Footer_admin></Footer_admin>

        
    </div>
  );
}

export default Edit_order;