import React, { useState } from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Experiment from "./Pages/Experiment/Exp";

import Home from "./Pages/Home/Home";
import Tracking from "./Pages/Tracking/Tracking";
import Cektarif from "./Pages/Cektarif/Cektarif";
import Portofolio from "./Pages/About/Portofolio";
import Services from "./Pages/About/Services";
import Superiority from "./Pages/About/Superiority";
import Termsandcondition from "./Pages/About/Termsandcondition";
import Contact from "./Pages/Contact/Contact";

import Dashboard_admin from "./Pages/Admin/Dashboard/Dashboard";
import Users_admin from "./Pages/Admin/Users/Users";
import Edit_users_admin from "./Pages/Admin/Users/Edit_users";
import Edit_profile_admin from "./Pages/Admin/Profile/Edit_profile";
import Add_users_admin from "./Pages/Admin/Users/Add_users";
import Country_admin from "./Pages/Admin/Country/Country";
import Edit_country_admin from "./Pages/Admin/Country/Edit_country";
import Typeprice_admin from "./Pages/Admin/Type_price/Type_price";
import Edit_typeprice_admin from "./Pages/Admin/Type_price/Edit_type_price";
import Price_admin from "./Pages/Admin/Price/Price";
import Edit_price_admin from "./Pages/Admin/Price/Edit_price";
import Typeitem_admin from "./Pages/Admin/Type_item/Type_item";
import Add_typeitem_admin from "./Pages/Admin/Type_item/Add_type_item";
import Edit_typeitem_admin from "./Pages/Admin/Type_item/Edit_type_item";
import Profile_admin from "./Pages/Admin/Profile/Profile";
import Changepassword_admin from "./Pages/Admin/Profile/Changepassword";
import Luwjistik_order from "./Pages/Admin/Luwjistik/Order";
import Luwjistik_add_order from "./Pages/Admin/Luwjistik/Add_order";
import Luwjistik_edit_order from "./Pages/Admin/Luwjistik/Edit_order";
import Aramex_order from "./Pages/Admin/Aramex/Order";
import Aramex_add_order from "./Pages/Admin/Aramex/Add_order";
import Aramex_edit_order from "./Pages/Admin/Aramex/Edit_order";
import Dhl_order from "./Pages/Admin/Dhl/Order";
import Dhl_add_order from "./Pages/Admin/Dhl/Add_order";
import Dhl_edit_order from "./Pages/Admin/Dhl/Edit_order";
import Notfound from "./Pages/Notfound/Notfound";
import Admin from "./Pages/Admin/Login/Login";
import {UserContext} from './Context/context';
//import App from "./App";
import reportWebVitals from "./reportWebVitals";
import createBrowserHistory from 'history/createBrowserHistory';





export default function App() {
  const [user, setUser] = useState();
  const history = createBrowserHistory();
    return (
      <div>
        <Router forceRefresh={false} history={history}>
          <div>
            <Switch>
            <UserContext.Provider value={{user,setUser}} >
              <Route
                exact
                path="/"
                render={() => {
                    return (
                      <Redirect to="/halaman_utama" />
                    )
                }}
              />
              <Route exact path="/exp" component={Experiment} />

              <Route exact path="/halaman_utama" component={Home} />
              <Route exact path="/portofolio" component={Portofolio} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/superiority" component={Superiority} />
              <Route exact path="/termsandcondition" component={Termsandcondition} />
              <Route exact path="/kontak" component={Contact} />
              <Route exact path="/tracking" component={Tracking} />
              <Route exact path="/cektarif" component={Cektarif} />

              <Route forceRefresh={true} exact path="/admin" component={Admin} />
              <Route exact path="/dashboard" component={Dashboard_admin} />
              <Route exact path="/users" component={Users_admin} />
              <Route exact path="/edit_users" component={Edit_users_admin} />
              <Route exact path="/edit_profile" component={Edit_profile_admin} />
              <Route exact path="/add_users" component={Add_users_admin} />
              <Route exact path="/country" component={Country_admin} />
              <Route exact path="/edit_country" component={Edit_country_admin} />
              <Route exact path="/type_price" component={Typeprice_admin} />
              <Route exact path="/edit_type_price" component={Edit_typeprice_admin} />
              <Route exact path="/price" component={Price_admin} />
              <Route exact path="/edit_price" component={Edit_price_admin} />
              <Route exact path="/type_item" component={Typeitem_admin} />
              <Route exact path="/add_type_item" component={Add_typeitem_admin} />
              <Route exact path="/edit_type_item" component={Edit_typeitem_admin} />
              <Route exact path="/profile" component={Profile_admin} />
              <Route exact path="/changepassword" component={Changepassword_admin} />
              <Route exact path="/luwjistik_order" component={Luwjistik_order} />
              <Route exact path="/luwjistik_add_order" component={Luwjistik_add_order} />
              <Route exact path="/luwjistik_edit_order" component={Luwjistik_edit_order} />
              <Route exact path="/aramex_order" component={Aramex_order} />
              <Route exact path="/aramex_add_order" component={Aramex_add_order} />
              <Route exact path="/aramex_edit_order" component={Aramex_edit_order} />
              <Route exact path="/dhl_order" component={Dhl_order} />
              <Route exact path="/dhl_add_order" component={Dhl_add_order} />
              <Route exact path="/dhl_edit_order" component={Dhl_edit_order} />
              <Route exact path="/notfound" component={Notfound} />
              </UserContext.Provider>
            </Switch>
          </div>
        </Router>
      </div>
    );
  
}

render(<React.StrictMode><App /></React.StrictMode>, document.getElementById("root"));
reportWebVitals();